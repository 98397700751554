/**
 * TODO:
 * - Add filters
 * - Threat actors not displaying
 * - do threat sorting on DB
 * 
 */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Breadcrumb,
  Button,
  Drawer,
  Col,
  Input,
  Row,
  Select,
  Table,
  Tag,
  Typography,
} from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined
} from "@ant-design/icons";
import PageLayout from '../pageLayout/application';
import Spacer from '../common/spacer';
import API from '../../constants/api';
import { userDataStore } from "../../store/user";
import {THREAT_CATEGORIES, getThreatCategory} from '../../constants/threatCategories';
import { THREAT_ACTORS, getThreatActor } from '../../constants/threatActors';
import { THREAT_RISK, getThreatRisk } from '../../constants/threatRisk';
import { addThreatTagToOrganization } from '../../utils/utils';
import sidebarComponents from '../../data/canvasComponents';
import TagsInput from '../common/TagsInput';
import useDocumentTitle from '../../utils/pageTitle';
import ThreatForm from "../common/ThreatForm";


const { Title } = Typography;
const { TextArea } = Input;
const { Option, OptGroup } = Select;
const { Column } = Table;

const ThreatCatalog = () => {
  useDocumentTitle("Threat Library");
  const userData = userDataStore((state) => state.userData);
  const [isThreatDetailsDrawerOpen, setThreatDetailsDrawerOpen] = useState(false);
  const [threatDetailsDrawerTitle, setThreatDetailsDrawerTitle] = useState("");
  const [threatFormDrawerTitle, setThreatFormDrawerTitle] = useState("Add a new threat");
  const [threatFormDrawerMode, setThreatFormDrawerMode] = useState("NEW");
  const [threatDetail, setThreatDetail] = useState({});
  const [editThreatObj, setEditThreatObj] = useState({});
  const [isNewThreatDrawerOpen, setNewThreatDrawerOpen] = useState(false);
  const [isCatalogLoading, setIsCatalogLoading] = useState(true);

  const defaultThreatData = {
    threatTitle: '',
    threatDescription: '',
    threatSummary: '',
    threatCategories: '',
    threatActor: '',
    threatRisk: '',
    threatMitigationList: [],
    threatTags: [],
    threatComponents: null
  };
  
  const [formData, setFormData] = useState(defaultThreatData);

  const handleNewThreatSubmit = async (obj) => {
    obj.organization_id = "community";
    await axios
      .post(
        API.CREATE_THREAT_IN_LIBRARY,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: userData.access_token
          }
        }
      ).then((response) => {
        setNewThreatDrawerOpen(false);
      })
      .catch((error) => {
        console.error('Error creating threat', error);
      });
      setIsCatalogLoading(true);
      fetchThreatCatalog();
  };

  const handleEditThreatSubmit = (obj) => {
    obj.organization_id = "community";
    axios
      .put(
        `${API.THREAT_CATALOG}/${editThreatObj.threat_id}`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: userData.access_token
          }
        }
      ).then((response) => {
        setNewThreatDrawerOpen(false);
      })
      .catch((error) => {
        console.error('Error creating threat', error);
      });
      setIsCatalogLoading(true);
      fetchThreatCatalog();
  };

  const [threats, setThreats] = useState([]);

  const fetchThreatCatalog = () => {
    axios
      .get(API.THREAT_CATALOG, {
        headers: {
          "Content-Type": "application/json",
          Authorization: userData.access_token,
        },
      })
      .then((response) => {
        setIsCatalogLoading(false);
        const sortedThreats = response.data.sort((a, b) => {
          // Convert the date strings to Date objects
          const dateA = new Date(a.last_modified_date);
          const dateB = new Date(b.last_modified_date);
        
          // Compare the Date objects
          return dateB - dateA;
        });
        
        setThreats(sortedThreats);
      })
      .catch((error) => {
        setIsCatalogLoading(false);
        console.error('Error fetching threats', error);
      });
  }

  useEffect(() => {
    fetchThreatCatalog();
  }, []);

  const showNewThreatDrawer = () => {
    setThreatFormDrawerTitle("Add a new threat");
    setNewThreatDrawerOpen(true);
    setFormData(defaultThreatData);
  };
  
  
  // OPEN THREAT DRAWER TO VIEW THREAT DETAILS
  const openThreatDetailsDrawer = (threat) => {
    setThreatDetailsDrawerTitle(threat.threat_title)
    setThreatDetailsDrawerOpen(true);
    setThreatDetail(threat);
  };
  
  const closeThreatDetailsDrawer = () => {
    setThreatDetailsDrawerOpen(false);
  };

  const editThreat = (threatObj) => {
    setEditThreatObj(threatObj);
    setThreatFormDrawerMode('EDIT');
    setThreatFormDrawerTitle('Edit threat');
    setNewThreatDrawerOpen(true);
  }

  return (
    <PageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Threat Library',
          },
          {
            title: 'Trusthive',
          },
        ]}
      />
      <Row>
        <Col span={14}>
          <Title>Trusthive threat catalog</Title>
        </Col>
        <Col span={10}>
          <div style={{ textAlign: "right" }}>
            <Button style={{ marginTop: '40px' }} type="primary" onClick={showNewThreatDrawer}>
              New Threat
            </Button>
          </div>
        </Col>
      </Row>
      
      <Title level={5}>{threats.length} threats found</Title>
      {threats &&
      <>
        <Table
          loading={isCatalogLoading}
          rowKey="threat_id"
          dataSource={threats}
        >
          <Column
            title="Threat"
            dataIndex="threat_title"
            key="threat_title"
            render={(project_title, threat) => {
              return (<Button type="link" onClick={() => {openThreatDetailsDrawer(threat)}} style={{paddingLeft: 0}}>{threat.threat_title}</Button>);
            }}
          />
          <Column
            title="Risk"
            dataIndex="risk"
            key="risk"
            render={(project_title, threat) => {
              return (
                <>
                  {threat.risk && <Tag color={getThreatRisk(threat.risk).color}>{getThreatRisk(threat.risk).id}</Tag>}
                </>
              );
            }}
          />
          <Column
            title="Categories"
            dataIndex="threat_categories"
            key="threat_categories"
            render={(project_title, threat) => {
              return (
                <>
                  {threat.threat_categories && <>
                    {threat.threat_categories.map((cat, index) => (
                      <Tag key={index}>{getThreatCategory(cat)}</Tag>
                    ))}
                  </>}
                </>
              );
            }}
          />
          <Column
            title="Actions"
            dataIndex="threat_categories"
            key="threat_categories"
            render={(project_title, threat) => {
              return (<Button onClick={() => {editThreat(threat)}}>
                Edit
              </Button>);
            }}
          />
        </Table>
      </>
      }


      <Drawer
        title= {threatDetailsDrawerTitle}
        onClose={closeThreatDetailsDrawer}
        open={isThreatDetailsDrawerOpen}
        width={800}
        extra={
          <Button onClick={() => {editThreat(threatDetail)}}>Edit threat</Button>
        }
      >
        <>
          <div className='threatBlurb'>
            <Row>
              <Col span={6}>
                <label>Risk</label> 
                {threatDetail.risk && <Tag color={getThreatRisk(threatDetail.risk).color}>{getThreatRisk(threatDetail.risk).id}</Tag>}
              </Col>
              <Col span={18}>
                {threatDetail.threat_categories &&
                <>
                  <label>Categories</label> 
                  {threatDetail.threat_categories.map((cat, index) => (
                    <Tag key={index}>{getThreatCategory(cat)}</Tag>
                  ))}
                </>}
              </Col>
            </Row>
            <Spacer size={25} />
            <div>
                {threatDetail.tags &&
                <>
                  <label>Tags</label>
                  {threatDetail.tags.map((tag, index) => (
                    <Tag key={index}>{tag}</Tag>
                  ))}
                </>}
            </div>
            <Spacer size={25} />
            <div>
              <label>Description</label>
              <p>{threatDetail.threat_description}</p>
            </div>
            <Spacer size={25} />
            <div>
              <label>Affected components</label>
              {threatDetail.components &&
                <>
                {threatDetail.components.map((item, index) => (
                  <p key={index}>- {item}</p>
                ))}
                </>
              }
            </div>
            <Spacer size={25} />
            <div>
              {threatDetail.mitigation_list &&
                <>
                  <label>Mitigation list</label>
                  {threatDetail.mitigation_list.map((item, index) => (
                    <p key={index}>- {item.title}</p>
                  ))}
                </>
              }
            </div>
          </div>
        </>
      </Drawer>

      
      <Drawer
        title={threatFormDrawerTitle}
        onClose={() => setNewThreatDrawerOpen(false)}
        open={isNewThreatDrawerOpen}
        width={800}
        className="drawerForm"
      >
        <ThreatForm 
          exitThreatForm={() => setNewThreatDrawerOpen(false)}
          onNewThreatSubmit={threatFormDrawerMode === 'EDIT' ?  handleEditThreatSubmit : handleNewThreatSubmit}
          addThreatToProject={false}
          addThreatToOrg={false}
          threatData={editThreatObj}
        />
      </Drawer>
    </PageLayout>
  );
};

export default ThreatCatalog;