import React, { useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Drawer,
  Timeline,
  Typography
} from 'antd';
import { PROJECT_PHASE_SORT } from '../../constants/app';
import { Check2Square, Square } from 'react-bootstrap-icons';

const { Title } = Typography;

const ChecklistDrawer = ({ onClose, open, projectDetails, saveProjectDetailsObjects }) => {
  const {checklist} = projectDetails;
  const sortedChecklist = checklist.sort((a, b) => {
    return PROJECT_PHASE_SORT.indexOf(a.phase) - PROJECT_PHASE_SORT.indexOf(b.phase);
  });

  return (
    <Drawer
      title="Security Checklist"
      placement="right"
      onClose={onClose}
      open={open}
      size={'large'}
      mask={false}
    >
      <div className="defaultMessage">
        Below is a checklist of all the tasks you should go over to take this service to production.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </div>

      <div className="checklistTimelines">
        <Timeline>
            {sortedChecklist.map((c, index) => (
              <Timeline.Item color="blue" key={index} dot={c.phase === "REQUIREMENTS" ? <Check2Square /> : <Square />}>
                <Title level={5}>{c.title}</Title>
                <p>{c.description}</p>
              </Timeline.Item>
            ))}
          </Timeline>
        </div>
    </Drawer>
  );
};
export default ChecklistDrawer;