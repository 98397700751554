import React from 'react';
import PageLayout from '../pageLayout/application';

const Templates = () => (
  <PageLayout>
    <React.Fragment>
      This is templates
    </React.Fragment>
  </PageLayout>
);

export default Templates;