import { memo,useState,useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import {
  getRectOfNodes,
  NodeToolbar,
  useReactFlow,
  useStore,
  useStoreApi,
  NodeResizer,
} from "reactflow";
import {
  Button,
  Input,
  Tooltip 
} from "antd";
import {
  DeleteOutlined,
  MessageOutlined
} from '@ant-design/icons';
import useDetachNodes from "./useDetachNodes";
import API from "../../constants/api";
import { userDataStore } from '../../store/user';
import Toolbar from "../common/CommentPopover";
import CommentPopover from "../common/CommentPopover";
import MetadataPopover from "../common/MetadataPopover";
import sidebarComponents from "../../data/canvasComponents";

const lineStyle = { borderColor: "white" };
const padding = 25;

const GroupNode = (props,{handleNodeTitleChange}) => {
  const userData = userDataStore(state => state.userData);
  const { id, projectDetails, saveProjectDetailsObjects } = props;
  const [showCommentPopover, setShowCommentPopover] = useState(false);
  const [comments, setComments] = useState([]);
  const [nodeText, setNodeText] = useState(props.data.title);
  const [textVal, setTextVal] = useState();
  const store = useStoreApi();
  const { deleteElements } = useReactFlow();
  const detachNodes = useDetachNodes();

  let groupNodeObj = sidebarComponents
    .map((group) => group.components)
    .flat()
    .find((comp) => comp.key === props.data.key);



  const { minWidth, minHeight, hasChildNodes } = useStore((store) => {
    const childNodes = Array.from(store.nodeInternals.values()).filter(
      (n) => n.parentNode === id
    );
    const rect = getRectOfNodes(childNodes);

    return {
      minWidth: rect.width + padding * 2,
      minHeight: rect.height + padding * 2,
      hasChildNodes: childNodes.length > 0,
    };
  }, isEqual);

  useEffect(() => {
    setComments(projectDetails.comments || []);
  }, [projectDetails]);

  const onDelete = () => {
    deleteElements({ nodes: [{ id }] });
  };

  const onDetach = () => {
    const childNodeIds = Array.from(store.getState().nodeInternals.values())
      .filter((n) => n.parentNode === id)
      .map((n) => n.id);

    detachNodes(childNodeIds, id);
  };
  const [isEditing, setIsEditing] = useState(false);
  const handleNodeClick = () => {
    setIsEditing(true);
  };
  const handleNodeBlur = () => {
    setIsEditing(false);
    props.data.title = nodeText;
    setTextVal(nodeText);
    handleNodeTitleChange(id, nodeText);
  };
  const handleNodeTextChange = (event) => {
    const updateNodeText = event.target.value;
     setNodeText(updateNodeText);
 
   };
  return (
    <div className="group-node" style={{ minWidth, minHeight }}>
      {/* {node && node.icon} */}
      <div className="group-node-label" onDoubleClick={handleNodeClick}>
        {isEditing ? (
          <Input
            type="text"
            value={nodeText}
            onChange={handleNodeTextChange}
            onBlur={handleNodeBlur}
            autoFocus
            className="nodeInput"
          />
        ) : (
          <>
            {groupNodeObj.icon} {nodeText}
          </>
        )}
      </div>
      <NodeResizer
        lineStyle={lineStyle}
        minWidth={minWidth}
        minHeight={minHeight}
      />
      <NodeToolbar className="nodrag nodeToolBar">
        <Tooltip title="Delete component">
          <Button onClick={onDelete} shape="circle" icon={<DeleteOutlined />} />
        </Tooltip>
        <Tooltip title="Add a comment">
          <Button shape="circle" icon={<MessageOutlined />} onClick={() => setShowCommentPopover(true)} />
        </Tooltip>
        
        <MetadataPopover
          id={id}
          projectDetails={projectDetails}
          componentType= "node"
        />

        {hasChildNodes && <button onClick={onDetach}>Ungroup</button>}
      </NodeToolbar>
      <CommentPopover
        id={id}
        showCommentPopover={showCommentPopover}
        setShowCommentPopover={setShowCommentPopover}
        projectDetails={projectDetails}
        saveProjectDetailsObjects={saveProjectDetailsObjects}
        comments={comments}
        nodeText="Internet"/>
    </div>
  );
}

function isEqual(prev, next) {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  );
}

export default memo(GroupNode);