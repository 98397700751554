/**
 * TODO:
 * - Add filters
 * 
 */

import React, { useState, useEffect } from 'react';
import pull from "lodash/pull";
import axios from 'axios';
import {
  AutoComplete,
  Button,
  Col,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Typography,
} from 'antd';
import {
  SearchOutlined
} from "@ant-design/icons";
import API from '../../constants/api';
import { userDataStore } from "../../store/user";

const TagsInput = (data) => {
    const {onTagSelect, api, orgId, newTagCallback, value} = data;
    const userData = userDataStore((state) => state.userData);
    const [tagOptions, setTagOptions] = useState([]);
    const [tagSearchValue, setTagSearchValue] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
  
  const onOptionSelect = (value, inst) => {
    if(inst.new) {
      newTagCallback(inst.label, orgId, userData.access_token)
    }
    selectedTags.push(inst.label);
    setTagSearchValue('');
    onTagSelect(selectedTags);
  };

  const removeSelectedTag = (t) => {
    pull(selectedTags, t);
    onTagSelect(selectedTags);
  };


  const handleTagSearch = async (query) => {
    setTagSearchValue(query)
    if(query !== '') {
        await axios.get(`${api}/${orgId}/${query}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: userData.access_token
            }
          }).then((response) => {
            if(response.data.length) {
              const objs = response.data.map(t => ({
                value: t.label,
                label: t.label
              }));
              setTagOptions(objs);
            } else {
              setTagOptions([{
                value: query,
                label: query,
                new: true
              }]);
            }
          }).catch((err) => {
            console.log('Error' + err)
          });
    }
  }

  useEffect(() => {
    if(value.length) {
      setSelectedTags(value);
    }
  }, [selectedTags]);
  
  return (
    <div>
        <div className="autoCompleteInput">
            <AutoComplete
                options={tagOptions}
                open={tagSearchValue !== ''}
                onSearch={(text) => handleTagSearch(text)}
                onSelect={onOptionSelect}
                placeholder="Select or type tags"
                allowClear={true}
                value= {tagSearchValue}
            />
            <SearchOutlined />
        </div>
        {selectedTags.length > 0 &&
            <div className="tagsWrapper">
                {selectedTags.map(function (tag, index) {
                    return (
                      <Tag key={index} closable={true} onClose={(e) => {e.preventDefault(); removeSelectedTag(tag)}}>
                          {tag}
                      </Tag>
                    )
                })}
            </div>
        }
    </div>
  );
};

export default TagsInput;