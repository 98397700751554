import {
    BoundingBox,
    BroadcastPin,
    Bug,
    CloudUpload,
    Clouds,
    CodeSquare,
    Display,
    FileRuled,
    Magic,
    Phone,
    ShieldCheck
} from "react-bootstrap-icons";

const PROJECT_STATUS = {
    DRAFT: 'DRAFT',
    PENDING_REVIEW: 'PENDING_REVIEW',
    IN_REVIEW: 'IN_REVIEW',
    SECURITY_APPROVED: 'SECURITY REVIEW DONE',
    REJECTED: 'REJECTED',
    PRODUCTION: 'PRODUCTION',
};

const PROJECT_TYPE = [
    {
        key: "WEB_APP",
        label: "Web Application",
        description: "Some text",
        icon: <Display />
    },
    {
        key: "MOBILE_APP",
        label: "Mobile Application",
        description: "Some text",
        icon:  <Phone />
    },
    {
        key: "CLOUD_SERVICE",
        label: "Cloud Service",
        description: "Some text",
        icon: <Clouds />
    },
    {
        key: "DS_AI_ML",
        label: "Data Science / AI / ML",
        description: "Some text",
        icon: <Magic />
    },
    {
        key: "IOT",
        label: "IoT / Embedded Software",
        description: "Some text",
        icon: <BroadcastPin />
    },
    {
        key: "OTHER",
        label: "Other",
        description: "Some text",
        icon: <BoundingBox />
    }
];


// Source: 
// - https://snyk.io/learn/secure-sdlc/
// - https://www.paloaltonetworks.com/cyberpedia/what-is-secure-software-development-lifecycle

const PROJECT_PHASE_SORT = [
    "REQUIREMENTS",
    "DESIGN_PLANNING",
    "DEVELOPMENT",
    "TESTING",
    "DEPLOYMENT",
    "MAINTENANCE"
];
const PROJECT_PHASE = [
    {
        key: "REQUIREMENTS",
        label: "Requirements",
        description: "Requirement gathering. Risk Assessment",
        icon: <FileRuled />
    },
    {
        key: "DESIGN_PLANNING",
        label: "Design & Planning",
        description: "Threat Modeling",
        icon:  <BoundingBox />
    },
    {
        key: "DEVELOPMENT",
        label: "Development",
        description: "Static Code Analysis",
        icon: <CodeSquare />
    },
    {
        key: "TESTING",
        label: "Security & Code Testing",
        description: "Code reviews",
        icon: <Bug />
    },
    {
        key: "DEPLOYMENT",
        label: "Deployment",
        description: "Security Assessment & Secure config",
        icon: <CloudUpload />
    },
    {
        key: "MAINTENANCE",
        label: "Maintenance",
        description: "Resource changes. Bugs. Vulnerabilities",
        icon: <ShieldCheck />
    }
];

export {
    PROJECT_STATUS,
    PROJECT_TYPE,
    PROJECT_PHASE,
    PROJECT_PHASE_SORT
}