import React, { useState } from 'react';
import {
    FloatButton,
    Input,
    Modal
} from 'antd';
import { ChatSquareTextFill } from 'react-bootstrap-icons';

const { TextArea } = Input;

const createClickUpTask = async (feedback, user) => {
    const clickUpListId = '901103564622'; // Replace with your ClickUp List ID
    const clickUpApiKey = 'pk_10569638_WRJ96ZYO08M0Z699DE1HKEFUOW718MZI'; // Replace with your ClickUp API Key
    
    const response = await fetch(`https://api.clickup.com/api/v2/list/${clickUpListId}/task`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': clickUpApiKey,
      },
      body: JSON.stringify({
        name: `${user.full_name}`,
        description: feedback
      }),
    });
  
    if (response.ok) {
      console.log('Task created successfully');
    } else {
      console.error('Error creating task');
    }
};

const Feedback = ({user}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [feedback, setFeedback] = useState('');

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission here
        await createClickUpTask(feedback, user);
        closeModal();
    };

    return (
        <div>
        <FloatButton
            onClick={openModal}
            icon={<ChatSquareTextFill />}
            className="feedback-button"
            >
                Report Feedback/Bug
            </FloatButton>
            <Modal
                title={<><ChatSquareTextFill /> Help us improve!</>}
                open={modalIsOpen}
                onOk={handleSubmit}
                onCancel={closeModal}
                width={600}
                okText="Submit"
                okButtonProps = {feedback === '' ? {disabled : true} : null}
            >
                <TextArea 
                    placeholder="Type in your feedback,suggestions or bugs you may have found" 
                    autoSize={{ minRows: 3, maxRows: 6}} 
                    value={feedback}
                    onChange={(e) => {setFeedback(e.target.value)}} />
        </Modal>
        </div>
    );
};

export default Feedback;