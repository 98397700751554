import React, { useState, useEffect } from "react";
import axios from "axios";
import PageLayout from '../pageLayout/application';
import {
    Alert,
    Button,
    Col,
    Input,
    Modal,
    message,
    Row,
    Select,
    Table,
    Tag,
    Typography,
    Tabs,
} from "antd";
import {
    EllipsisOutlined,
    UserAddOutlined
  } from "@ant-design/icons";
import API from "../../constants/api";
import Spacer from "../common/spacer";
import { userDataStore } from "../../store/user";
import Avatar from "../common/Avatar";
import { Amazon, Github, Slack } from "react-bootstrap-icons";
import useDocumentTitle from "../../utils/pageTitle";


const { Title } = Typography;
const { Column } = Table;

const Workspace = () => {
    useDocumentTitle("Workspace");
    const [messageApi, contextHolder] = message.useMessage();
    const [isDataLoading, setIsDataLoading] = useState(true);
    const userData = userDataStore((state) => state.userData);
    const [workspaceDetails, setWorkspaceDetails] = useState({});
    
    const getWorkspaceDetails = () => {
        axios
          .get(`${API.WORKSPACE_DETAILS}/${userData.data.organization_id}`,
            {
              headers: {
              "Content-Type": "application/json",
              Authorization: userData.access_token,
            },
          })
          .then((response) => {
            setWorkspaceDetails(response.data);
          })
          .catch((err) => {
            messageApi.open({
              type: 'error',
              content: `There was an error: ${err}`,
              className: 'error-message',
              duration: 5
            });
          });
    }
    
    /** GET ORG DETAILS */
    useEffect(() => {
      getWorkspaceDetails();
    }, []);

    
    return (
        <>
            {contextHolder}
            <PageLayout>
                <Row>
                    <Col span={14}>
                        <Title>Workspace</Title>
                    </Col>
                    <Col span={10}>
                        <div style={{ padding: "45px 0 0 0", textAlign: "right" }}>
                            <Button type="primary">
                                Invite Members
                            </Button> 
                        </div>
                    </Col>
                </Row>

                {workspaceDetails &&
                <div className="bold-form">
                  <Row>
                    <Col span={8}>
                      <div className="form-field">
                        <label>Workspace name</label>
                        <p>{workspaceDetails.organization_name}</p>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="form-field">
                        <label>Workspace type</label>
                        <p>{workspaceDetails.organization_type}</p>
                      </div>
                    </Col>
                  </Row>
                
                  <div className="form-field">
                    <label>Admin contact</label>
                    <p>{workspaceDetails.created_by_email}</p>
                  </div>
                </div>
                }
                <div style={{borderBottom: '1px solid #EAEAEA'}}></div>
                <Spacer size={25} />
                <h2>
                  Integrations 
                  <span style={{color: '#cd58ff', fontSize: '.7rem'}}> COMING SOON</span>
                </h2>
                <Spacer size={25} />
                <Row gutter={25}>
                  <Col span={8}>
                    <div className="integration-card">
                      <Row>
                        <Col span={3} className="logo"><Github /></Col>
                        <Col span={21}>
                          <h3>Github</h3>
                          <p>
                            Sync to Github projects/teams. Create issues & tasks for your projects.
                            Pull in repo info and sync terraform scripts.
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="integration-card">
                      <Row>
                        <Col span={3} className="logo"><Slack /></Col>
                        <Col span={21}>
                          <h3>Slack</h3>
                          <p>
                            Bring Trusthive into slack with notifications and actions
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="integration-card">
                      <Row>
                        <Col span={3} className="logo"><Github /></Col>
                        <Col span={21}>
                          <h3>Jira</h3>
                          <p>
                            Sync to Jira projects/epics/etc. Create bugs & user stories for your projects.
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="integration-card">
                      <Row>
                        <Col span={3} className="logo"><Amazon /></Col>
                        <Col span={21}>
                          <h3>AWS</h3>
                          <p>
                            Synchronize AWS DevOps with Trusthive
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
            </PageLayout>
        </>
    );
}

export default Workspace;