import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import {
    AppstoreOutlined,
    AuditOutlined,
    BellOutlined,
    BulbOutlined,
    HomeOutlined,
    ProjectOutlined,
    QuestionCircleOutlined,
    SearchOutlined,
    TeamOutlined,
  } from '@ant-design/icons';
import {
    Avatar,
    Col,
    Input,
    Layout,
    Menu,
    Row,
    Space,
    theme
} from 'antd';
import { ReactComponent as LogoSVG } from '../../assets/brand-logo.svg';

const { Header, Content } = Layout;


const FullPageLayout = ({ children }) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ paddingLeft: "20px", background: colorBgContainer }}>
                <Row>
                    <Col span={5}>
                        <div style={{display:"inline-block", width:"50px",height:"50px", verticalAlign: "middle"}} >
                            <LogoSVG />
                        </div>
                        <span style={{fontWeight: 600, fontSize: 24,padding:"10px 0 0 5px"} }>Trusthive</span>
                    </Col>
                </Row>
            </Header>
            <Content>
                <div style={{ flexDirection: 'column', display: 'flex', flexGrow: 1, height: 'calc(100vh - 68px)' }}>
                    {children}
                </div>
            </Content>
        </Layout>
)};

export default FullPageLayout;