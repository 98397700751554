import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Alert,
  AutoComplete,
  Button,
  Col,
  Input,
  List,
  Modal,
  message,
  Row,
  Select,
  Table,
  Tag,
  Typography,
  Tabs,
} from "antd";
import {
    BulbOutlined,
    CheckCircleOutlined
} from "@ant-design/icons";
import API from "../../constants/api"
import Spacer from "../common/spacer";
import Avatar from "../common/Avatar";
import { getBadgeColor } from "../../utils/utils";
import { userDataStore } from "../../store/user";
import { PROJECT_STATUS } from "../../constants/app";


const ProjectStatusModal = ({
    isModalOpen,
    setModalOpen,
    projectDetails,
    saveProjectDetailsObjects
    }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const userData = userDataStore((state) => state.userData);
    const {DRAFT, SECURITY_APPROVED, PRODUCTION} = PROJECT_STATUS;

    const updateProjectStatus = (status) => {
        saveProjectDetailsObjects("project_status", status, () => {
            setModalOpen(false);
        }, () => {});
    }
    
    return(
        <>
            {contextHolder}
            <Modal
                title="Project Status"
                open={isModalOpen}
                onCancel={() => setModalOpen(false)}
                width={800}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <Alert
                    message="Update the project statuses"
                    description="Please click on one of the following options that best describes the current status for this project."
                    type="info"
                    showIcon
                    icon = {<BulbOutlined />}
                />
                <Spacer size={30} />
                <Row gutter={50}>
                    <Col span={8}>
                        <div
                            className={"project-status-blurb " + (projectDetails.project_status === DRAFT ? 'active-status' : 'selectable')}
                            onClick={(projectDetails.project_status === DRAFT ? null : () => updateProjectStatus(DRAFT))}
                        >
                            <div className="status-icon"><CheckCircleOutlined /></div>
                            <Tag color={getBadgeColor(DRAFT)}>
                                DRAFT
                            </Tag>
                            <p>
                                Select this status if your team is still in design or update phase to the project's Threat Model.
                            </p>
                        </div>
                    </Col>

                    <Col span={8}>
                        <div
                            className={"project-status-blurb " + (projectDetails.project_status === SECURITY_APPROVED ? 'active-status' : 'selectable')}
                            onClick={(projectDetails.project_status === SECURITY_APPROVED ? null : () => updateProjectStatus(SECURITY_APPROVED))}
                        >
                            <div className="status-icon"><CheckCircleOutlined /></div>
                            <Tag color={getBadgeColor(SECURITY_APPROVED)}>
                                SECURITY REVIEW DONE
                            </Tag>
                            <p>
                                Select this status if your team has reviewed all the threat recommednations,
                                taken into account all guardrails and mitigations.
                            </p>
                        </div>
                    </Col>

                    <Col span={8}>
                        <div
                            className={"project-status-blurb " + (projectDetails.project_status === PRODUCTION ? 'active-status' : 'selectable')}
                            onClick={(projectDetails.project_status === PRODUCTION ? null : () => updateProjectStatus(PRODUCTION))}
                        >
                            <div className="status-icon"><CheckCircleOutlined /></div>
                            <Tag color={getBadgeColor(PRODUCTION)}>
                                RUNNING IN PRODUCTION
                            </Tag>
                            <p>
                                Select this status if this project (service/app/platform) is already deployed & running in production.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Spacer size={30} />
            </Modal>
        </>
    )
};

export default ProjectStatusModal;