import find from "lodash/find";

const THREAT_CATEGORIES = [
    {
        "id": 1,
        "title": "Spoofing",
        "related_recommendations": [
            {
                "id": 51,
                "title": "Constant Time Comparison"
            },
            {
                "id": 20,
                "title": "Use a secure mechanism to manage your certificates for encryption in transit or signing"
            },
            {
                "id": 29,
                "title": "Implement secure HTTP Header information for Websites and Web API's"
            },
            {
                "id": 98,
                "title": "SMS-based 2FA Token Theft - Waiting Annual Review"
            },
            {
                "id": 54,
                "title": "User Password Storage: pbkdf2"
            },
            {
                "id": 53,
                "title": "User Password Storage: scrypt"
            },
            {
                "id": 38,
                "title": "Ensure your email infrastructure implements industry-standard antispoofing and encryption mechanisms"
            },
            {
                "id": 137,
                "title": "Prevent CSRF Attacks"
            },
            {
                "id": 50,
                "title": "Generating Secrets"
            },
            {
                "id": 138,
                "title": "Ensure the HttpOnly Flag is set for all cookies"
            },
            {
                "id": 95,
                "title": "Implement the right encryption mechanism to prevent Replay Attacks"
            },
            {
                "id": 61,
                "title": "Use prepared statements to avoid SQL injection"
            },
            {
                "id": 39,
                "title": "Ensure your DNS domains are properly monitored and maintained"
            }
        ]
    },
    {
        "id": 2,
        "title": "Tampering",
        "related_recommendations": [
            {
                "id": 28,
                "title": "Validate user input prior to processing the request"
            },
            {
                "id": 101,
                "title": "Preventing JSON Injection"
            },
            {
                "id": 8,
                "title": "Use a secure CI/CD pipeline for your project"
            },
            {
                "id": 41,
                "title": "Protect against Altered Binaries in Production - Pending Annual Review"
            },
            {
                "id": 35,
                "title": "Use an approved framework for software development"
            },
            {
                "id": 100,
                "title": "Preventing LDAP Injection"
            },
            {
                "id": 10,
                "title": "Scan your containers for vulnerabilities at build time - Waiting for Annual Review"
            },
            {
                "id": 99,
                "title": "Making XPATh/XQueries Securely"
            },
            {
                "id": 15,
                "title": "Use preapproved base images to build your services"
            },
            {
                "id": 27,
                "title": "Use adequate encoding mechanism to prevent security flaws"
            },
            {
                "id": 137,
                "title": "Prevent CSRF Attacks"
            },
            {
                "id": 95,
                "title": "Implement the right encryption mechanism to prevent Replay Attacks"
            },
            {
                "id": 61,
                "title": "Use prepared statements to avoid SQL injection"
            },
            {
                "id": 25,
                "title": "Ensure Endpoints are properly monitored by security agents"
            },
            {
                "id": 16,
                "title": "Store Artifacts in approved repositories"
            },
            {
                "id": 12,
                "title": "Ensure your artifacts are signed as part of your build process"
            },
            {
                "id": 11,
                "title": "Scan your code for known security vulnerabilities"
            },
            {
                "id": 96,
                "title": "Prevent malicious content in uploaded files"
            },
            {
                "id": 37,
                "title": "Separate CSS and Javascript into external files to allow for Content Security Policies"
            },
            {
                "id": 42,
                "title": "Protect against Configuration File Tampering"
            },
            {
                "id": 55,
                "title": "Hash vs. HMAC"
            },
            {
                "id": 134,
                "title": "Ensure your Service runs on FIPS 140-2 compliant images"
            }
        ]
    },
    {
        "id": 3,
        "title": "Repudiation",
        "related_recommendations": [
            {
                "id": 18,
                "title": "Use approved mechanisms to access your production environments"
            },
            {
                "id": 19,
                "title": "Ensure your logs reach the Security Detection org"
            },
            {
                "id": 34,
                "title": "Ensure inbound/outbound traffic flows through a Security Gateway in 1st Party Datacenters"
            },
            {
                "id": 58,
                "title": "Signing Messages"
            },
            {
                "id": 61,
                "title": "Use prepared statements to avoid SQL injection"
            }
        ]
    },
    {
        "id": 4,
        "title": "Info Disclosure",
        "related_recommendations": [
            {
                "id": 60,
                "title": "Configure XML parsers to reject DTDs"
            },
            {
                "id": 2,
                "title": "Use encryption in transit (HTTPS) to communicate between services"
            },
            {
                "id": 135,
                "title": "Ensure your Service uses FIPS Endpoints"
            },
            {
                "id": 101,
                "title": "Preventing JSON Injection"
            },
            {
                "id": 32,
                "title": "Use approved Key Management Systems (KMS) to generate and store encryption keys"
            },
            {
                "id": 1,
                "title": "Select the right authentication & authorization mechanism to identify and control access to users or services"
            },
            {
                "id": 57,
                "title": "Asymmetric Encryption - Pending Annual Review"
            },
            {
                "id": 4,
                "title": "Restrict the network connectivity to the required users or services (Network Segmentation)"
            },
            {
                "id": 100,
                "title": "Preventing LDAP Injection"
            },
            {
                "id": 99,
                "title": "Making XPATh/XQueries Securely"
            },
            {
                "id": 48,
                "title": "Setting up TLS"
            },
            {
                "id": 3,
                "title": "Use Encryption at Rest to protect stored information"
            },
            {
                "id": 47,
                "title": "Cryptography"
            },
            {
                "id": 61,
                "title": "Use prepared statements to avoid SQL injection"
            },
            {
                "id": 5,
                "title": "Filter and log outbound network calls"
            },
            {
                "id": 139,
                "title": "Ensure the Secure Flag is set for all cookies"
            },
            {
                "id": 9,
                "title": "Ensure you are not checking secrets into source control or config files"
            },
            {
                "id": 49,
                "title": "Setting up GRPC"
            },
            {
                "id": 46,
                "title": "Filter the content of your visible error messages"
            },
            {
                "id": 44,
                "title": "Ensure you use separate secrets in different environments"
            },
            {
                "id": 43,
                "title": "Ensure you keep secrets out of your logs"
            },
            {
                "id": 7,
                "title": "Use a secure source code management solution to store and manage code"
            },
            {
                "id": 56,
                "title": "Symmetric Encryption"
            },
            {
                "id": 134,
                "title": "Ensure your Service runs on FIPS 140-2 compliant images"
            }
        ]
    },
    {
        "id": 5,
        "title": "Denial of Service",
        "related_recommendations": [
            {
                "id": 13,
                "title": "Log security-related events and relevant information"
            },
            {
                "id": 14,
                "title": "Harden container orchestration service"
            },
            {
                "id": 23,
                "title": "Perform regular scans to identify known vulnerabilties and bad configs"
            },
            {
                "id": 36,
                "title": "Provide an access for centralized incident response"
            },
            {
                "id": 40,
                "title": "Plan for data loss, business continuity and disaster recovery (BCDR)"
            },
            {
                "id": 45,
                "title": "Define and enforce data upload limits"
            },
            {
                "id": 60,
                "title": "Configure XML parsers and reject DTDs"
            },
            {
                "id": 61,
                "title": "Utilize prepared statements to avoid SQL injection"
            }
        ]
    },
    {
        "id": 6,
        "title": "Elevation of Privilege",
        "related_recommendations": [
            {
                "id": 31,
                "title": "Prevent confuse deputy problem in software development"
            },
            {
                "id": 61,
                "title": "Use prepared statements to avoid SQL injection"
            },
            {
                "id": 96,
                "title": "Prevent malicious content in uploaded files"
            },
            {
                "id": 98,
                "title": "SMS-based 2FA Token Theft - Waiting Annual Review"
            }
        ]
    },
    {
        "id": 7,
        "title": "Remote Code Execution",
        "related_recommendations": [
            {
                "id": 33,
                "title": "Ensure your software is up-to-date"
            },
            {
                "id": 59,
                "title": "Use deserialization methods that don’t execute code"
            },
            {
                "id": 60,
                "title": "Configure XML parsers to reject DTDs"
            },
            {
                "id": 61,
                "title": "Use prepared statements to avoid SQL injection"
            },
            {
                "id": 96,
                "title": "Prevent malicious content in uploaded files"
            }
        ]
    }
]

const getThreatCategory = (t_id) => {
    let obj = find(THREAT_CATEGORIES, { id: t_id });
    return obj.title;
}


export {
    THREAT_CATEGORIES,
    getThreatCategory
};