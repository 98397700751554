import axios from 'axios';
import API from '../constants/api';
import { PROJECT_STATUS } from "../constants/app";

const getBadgeColor = (status) => {
    switch (status) {
        case PROJECT_STATUS.DRAFT:
          return "geekblue";
        case PROJECT_STATUS.SECURITY_APPROVED:
          return "purple";
        case PROJECT_STATUS.PENDING_REVIEW:
          return "warning";
        case PROJECT_STATUS.PRODUCTION:
          return "green";
        default:
          return "default"; // Use a default color for other statuses
      }
}

const addThreatTagToOrganization = (tag, organization_id, access_token) => {
  axios.post(
    API.CREATE_THREAT_TAG,
    {
      label: tag,
      organization_id: organization_id
    },
    {
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
  .then((response) => {
    console.log('tag created');
  })
  .catch((err) => {
    console.log("Error creating tag:", err);
  });
}

const addMetadataTagToOrganization = (tag,organization_id, access_token) => {
  axios.post(
    API.CREATE_COMPONENT_METADATA_TAG,
    {
      label: tag,
      organization_id: organization_id
    },
    {
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token,
    },
  })
  .then((response) => {
    console.log('Metadata tag created');
  })
  .catch((err) => {
    console.log("Error creating tag:", err);
  });
}

const findAndUpdateByAttribute = (obj, attributeName, attributeValue, updatedValues) => {
  // Check if the current object matches the search criteria
  if (obj && obj[attributeName] === attributeValue) {
      // Update the matched object with the provided values
      Object.assign(obj, updatedValues);
      return true; // Return true to indicate the object was found and updated
  }

  // If the current object is not a match, recursively search its children
  for (let key in obj) {
      if (typeof obj[key] === 'object') {
          // If a match is found in any child object, return true
          if (findAndUpdateByAttribute(obj[key], attributeName, attributeValue, updatedValues)) {
              return true;
          }
      }
  }

  // Return false if no matching object was found
  return false;
}

const isUserAuthorized = (userData) => {
  let f = false;
  if(userData && userData.data) {
    const { organization_id, onboarded } = userData.data ? userData.data : {};
    if(organization_id !== undefined && onboarded) {
      f = true;
    }
  }
  return f;
}

export {
  getBadgeColor,
  addThreatTagToOrganization,
  addMetadataTagToOrganization,
  findAndUpdateByAttribute,
  isUserAuthorized
};