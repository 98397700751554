const g_client_id = "975011100827-8cg5tt1f3nuu2ufcc9g7a3c7lt92l31v.apps.googleusercontent.com";
const g_client_secret = "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";
const g_obj = {
    "web":{
        "client_id": "975011100827-8cg5tt1f3nuu2ufcc9g7a3c7lt92l31v.apps.googleusercontent.com",
        "project_id":"trusthive-391014",
        "auth_uri":"https://accounts.google.com/o/oauth2/auth",
        "token_uri":"https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url":"https://www.googleapis.com/oauth2/v1/certs",
        "client_secret":"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",
        "redirect_uris":["http://localhost:3000"],
        "javascript_origins":["http://localhost:3000"]
    }
};

export default g_obj;