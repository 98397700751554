import React, { useState, useEffect } from "react";
import axios from "axios";
import PageLayout from '../pageLayout/application';
import {
    Alert,
    Button,
    Col,
    Input,
    Modal,
    message,
    Row,
    Select,
    Table,
    Tag,
    Typography,
    Tabs,
} from "antd";
import {
    EllipsisOutlined,
    UserAddOutlined
  } from "@ant-design/icons";
import API from "../../constants/api";
import { userDataStore } from "../../store/user";
import Avatar from "../common/Avatar";
import useDocumentTitle from '../../utils/pageTitle';


const { Title } = Typography;
const { Column } = Table;

const Directory = () => {
    useDocumentTitle("Directory");
    const [messageApi, contextHolder] = message.useMessage();
    const [isDataLoading, setIsDataLoading] = useState(true);
    const userData = userDataStore((state) => state.userData);
    const [isInviteUsersModalOpen, showInviteUsersModal] = useState(false);
    const [usersList, loadUsersList] = useState([]);
    const [inviteEmails, setInviteEmails] = useState('');

    const getUsersByOrg = () => {
        axios
          .get(`${API.USERS_BY_ORG}/${userData.data.organization_id}`,
            {
              headers: {
              "Content-Type": "application/json",
              Authorization: userData.access_token,
            },
          })
          .then((response) => {
            setIsDataLoading(false);
            console.log(response);
            loadUsersList(response.data);
          })
          .catch((err) => {
            messageApi.open({
              type: 'error',
              content: `There was an error: ${err}`,
              className: 'error-message',
              duration: 5
            });
          });
    }
    
    /** GET USERS */
    useEffect(() => {
        getUsersByOrg();
    }, []);

    
    const handleInviteModalCancel = () => {
        showInviteUsersModal(false);
        setInviteEmails(''); 
    };

   const handleInviteModalOk = () => {
        const emailsArray = inviteEmails.split(',').map(email => email.trim());

        // Call your backend API to send invitations
        axios.post(
          `${API.INVITE_USERS}/${userData.data.organization_id}`,
          {
            users: emailsArray,
            invitedBy: userData.data,
            role: "Member", // Set the default role or handle it appropriately
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: userData.access_token,
            },
          }
        )
        .then(response => {
          messageApi.success({
            content: "Invitations sent successfully!",
            className: 'success-message',
            duration: 3
          });

          // Optionally, you can refresh the users list after inviting
          getUsersByOrg();
        })
        .catch(err => {
          // Handle error (e.g., show error message)
          messageApi.error({
            content: `Error sending invitations: ${err}`,
            className: 'error-message',
            duration: 5
          });
        })
        .finally(() => {
            showInviteUsersModal(false);
          setInviteEmails('');
        });
    };

    
    return (
        <>
            {contextHolder}
            <PageLayout>
                <Row>
                    <Col span={14}>
                        <Title>Directory</Title>
                    </Col>
                    <Col span={10}>
                        <div style={{ padding: "45px 0 0 0", textAlign: "right" }}>
                            <Button type="primary" onClick={showInviteUsersModal}>
                                Invite Members
                            </Button> 
                        </div>
                    </Col>
                </Row>
                
                <Table
                    loading={isDataLoading}
                    rowKey="user_id"
                    dataSource={usersList}
                >
                    <Column title={ <span>User</span>}
                        dataIndex="full_name"
                        key="full_name"
                        render={(full_name, user) => {
                            return (
                            <span>
                                <Avatar userData={user} />
                                <span style={{marginLeft: 10}}>{full_name}</span>
                            </span>
                            );
                        }}
                    />
                    <Column title={ <span>Email</span>}
                        dataIndex="email"
                        key="email"
                        render={(email, user) => {
                            return (
                                <span>{email}</span>
                            );
                        }}
                    />
                    <Column
                        title="Settings"
                        dataIndex="icon"
                        key="icon"
                        render={(project_id, data) => (
                            <Button>
                                <EllipsisOutlined style={{fontSize: 21}} />
                            </Button>
                        )}
                    />
                </Table>

                {/* DELETE PROJECT MODAL */}
                <Modal
                title={<><UserAddOutlined style={{fontSize: 21, marginRight: 10}} /> Invite members to your organization</>}
                open={isInviteUsersModalOpen}
                onOk={handleInviteModalOk}
                onCancel={handleInviteModalCancel}
                okText="Invite users"
            >
                <div className="form-field">
                    <label>Add members to your workspace</label>
                    <Input.TextArea
                        value={inviteEmails}
                        onChange={(e) => setInviteEmails(e.target.value)}
                        placeholder="Email@mail.com, Email@mail.com, Email@mail.com"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                </div>
            </Modal>

            </PageLayout>
        </>
    );
}

export default Directory;