import { memo, useState,useEffect } from "react";
import axios from "axios";
import {
  Handle,
  NodeToolbar,
  useStore,
  useReactFlow,
} from "reactflow";
import {
  Button,
  Input,
  Tooltip 
} from "antd";
import {
  DeleteOutlined,
  MessageOutlined,
  UngroupOutlined
} from '@ant-design/icons';
import sidebarComponents from "../../data/canvasComponents";
import CommentPopover from "../common/CommentPopover";
import MetadataPopover from "../common/MetadataPopover";
import useDetachNodes from "./useDetachNodes";
import "./canvas.css";
import API from "../../constants/api";
import { userDataStore } from '../../store/user';

function CustomNode ({ id, data, projectDetails, saveProjectDetailsObjects, handleNodeTitleChange }, isConnectable) {
  const hasParent = useStore(
    (store) => !!store.nodeInternals.get(id)?.parentNode
  );
  const { deleteElements } = useReactFlow();
  const detachNodes = useDetachNodes();
  
  let node = sidebarComponents
    .map((group) => group.components)
    .flat()
    .find((comp) => comp.key === data.key);

  const [isEditing, setIsEditing] = useState(false);
  const [nodeText, setNodeText] = useState(data.title);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [metadataDetail, setMetadataDetail] = useState({});
  
  const [comments, setComments] = useState([]);
  useEffect(() => {
    setComments(projectDetails.comments || []);
    // TODO: Why do we need this??
  }, [projectDetails]);
  const [showCommentPopover, setShowCommentPopover] = useState(false);
  const [commentText, setCommentText] = useState('');
  const userData = userDataStore(state => state.userData);

  const showPopover = () => {
    setPopoverOpen(true);
  };
  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };


  const handleNodeClick = () => {
    setIsEditing(true);
  };


  const onDelete = () => deleteElements({ nodes: [{ id }] });
  const onDetach = () => detachNodes([id]);

  const handleNodeBlur = () => {
    setIsEditing(false);
    data.text = nodeText;
    setTextVal(nodeText);
    handleNodeTitleChange(id, nodeText);
  };

  const handleNodeTextChange = (event) => {
   const updateNodeText = event.target.value;
    setNodeText(updateNodeText);

  };
  const [textVal, setTextVal] = useState();

  return (
    <>
      <NodeToolbar className="nodrag nodeToolBar">
        <Tooltip title="Delete component">
          <Button onClick={onDelete} shape="circle" icon={<DeleteOutlined />} />
        </Tooltip>
        <Tooltip title="Add a comment">
          <Button shape="circle" icon={<MessageOutlined />} onClick={() => setShowCommentPopover(true)} />
        </Tooltip>

        <MetadataPopover
          id={id}
          projectDetails={projectDetails}
          componentType= "node"
        />

        {hasParent && 
          <Tooltip title="Detach from group">
            <Button shape="circle" onClick={onDetach} icon={<UngroupOutlined />} />
          </Tooltip>
        }
      </NodeToolbar>
      <Handle type="source" position="left" isConnectable={isConnectable} />
      <Handle
        type="source"
        position="right"
        id="a"
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position="top"
        id="b"
        style={{ background: "#555" }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position="bottom"
        id="c"
        style={{ background: "#555" }}
        isConnectable={isConnectable}
      />

      <CommentPopover
        id={id}
        showCommentPopover={showCommentPopover}
        setShowCommentPopover={setShowCommentPopover}
        projectDetails={projectDetails}
        saveProjectDetailsObjects={saveProjectDetailsObjects}
        comments={comments}
        nodeText={nodeText}
      />

      <div
        className="canvasNode"
        style={{ width: "100%", height: "100%" }}
        onDoubleClick={handleNodeClick}
        onClick={showPopover}
      >
        {node && node.icon}
        {isEditing ? (
          <Input
            type="text"
            value={nodeText}
            onChange={handleNodeTextChange}
            onBlur={handleNodeBlur}
            autoFocus
            className="nodeInput"
          />
        ) : (
          <div className="nodeLabel">{nodeText}</div>
        )}
      </div>
    </>
  );
}

export default memo(CustomNode);