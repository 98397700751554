import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { ReactComponent as LogoSVG } from '../../assets/brand-logo.svg';
import { ReactComponent as BBSVG } from '../../assets/building_blocks.svg';
import axios from "axios";
import { Button, Col, Row, Typography, Form, Input } from "antd";
import { GoogleOutlined } from '@ant-design/icons';
import "./login.css";
import { userDataStore } from "../../store/user";
import API from "../../constants/api";
import Spacer from "../common/spacer";

const { Title } = Typography;

const Login = () => {
  const navigate = useNavigate();
  const userData = userDataStore((state) => state.userData);
  const loginCurrentUser = userDataStore((state) => state.loginCurrentUser);
  
  /** When USER clicks Google login this is called */
  const doGooglelogin = useGoogleLogin({
    onSuccess: (authResponse) => {
      fetchUserDetailsFromGoogle(authResponse, 'login')
    },
    onError: (error) => console.log(`Login Failed: ${error}`),
  });

  /** FETCH USER INFO FROM GOOGLE  */
  const fetchUserDetailsFromGoogle = async (authResponse, mode) => {
    const { access_token } = authResponse;
    try {
      const googleUserInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v1/userinfo",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Accept: "application/json",
          },
        }
      );
      // TODO: CHECK IF USER EXISTS: GET USER BY EMAIL
      const trusthiveUser = await axios.get(
        `${API.CHECK_USER}/${googleUserInfo.data.email}`,
        {
          headers: {
            "Content-Type": "application/json"
          },
        }
      );
      if(trusthiveUser.data.user_exists === false) {
        // USER doesnt exist NOR invited. Create the user.
        saveUserDataToDB(googleUserInfo);
      } else {
        // USER EXISTS
        
        // USER SIGNED UP BUT HASNT BEEN ONBOARDED
        if(trusthiveUser.data.user.invited && !trusthiveUser.data.user.onboarded) {
          navigate("/thanks-beta-user");
        }
        else {
          // User is invited but signing in first time
          if(trusthiveUser.data.user.onboarded && !trusthiveUser.data.user.full_name) {
            console.log('=== User exists and NOT onboarded well ==');
            await updateUserInDB(trusthiveUser, googleUserInfo);
          } else {
            const loggedInUserObj = {};
            loggedInUserObj.data = trusthiveUser.data.user;
            loggedInUserObj.access_token = trusthiveUser.data.token
            await loginCurrentUser(loggedInUserObj);
          }
        }
      }
      
    } catch(error) {
      console.error("Unable to fetch user data from google:", error);
    }
  };

  /** SAVE USER DATA TO DB */
  const saveUserDataToDB = async (googleUserInfo) => {
    /** POST data to DB */
    const postUserData = {
      email: googleUserInfo.data.email,
      first_name: googleUserInfo.data.given_name,
      last_name: googleUserInfo.data.family_name,
      full_name: `${googleUserInfo.data.given_name} ${googleUserInfo.data.family_name}`,
      picture: googleUserInfo.data.picture,
      onboarded: false,
      invited:false
    }

    try {
      const thResponseData = await axios.post(
        API.USER,
        JSON.stringify(postUserData, null, 2),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      saveUserDataToStore(thResponseData, postUserData)
    } catch (error) {
      console.error("Unable to save user data:", error);
    }
    
  };

  /** Save data to Zustand store */
  const saveUserDataToStore = async (thResponseData, postUserData) => {
    try {
      const thUserDetails = await axios.get(
        `${API.USER}/${thResponseData.data.user_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thResponseData.data.token,
          },
        }
      );
      thUserDetails.access_token = thResponseData.data.token; // Need to find a better way for this.
      await loginCurrentUser(thUserDetails);
    } catch (error) {
      console.error("Failed to get user data:", error);
    }
  }

  /** When INVITED User logs in */
  const updateUserInDB = async (userObjFromDB, googleUserInfo) => {
    try {
      const thUser = await axios.put(
        `${API.USER}/${userObjFromDB.data.user.user_id}`,
        {
          first_name: googleUserInfo.data.given_name,
          last_name: googleUserInfo.data.family_name,
          full_name: googleUserInfo.data.name,
          picture: googleUserInfo.data.picture
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: userObjFromDB.data.token,
          },
        }
      );
      const loggedInUserObj = {};
      loggedInUserObj.data = thUser.data.user;
      loggedInUserObj.access_token = userObjFromDB.data.token
      await loginCurrentUser(loggedInUserObj);
    } catch (error) {
      console.error("Failed to get user data:", error);
    }
  }

  return (
    <>
      {userData.data ? (
        <>
          {userData.data.onboarded ? 
            <Navigate replace to={"/"} /> :
            <Navigate replace to={"/onboarding"} />
          }
        </>
      ) : (
        <div className="signup-page">
          <Row>
            <Col span={10} offset={2}>
              <div className="signup-image">
                <BBSVG style={{width:400, height: 400}}/>
                <Spacer size={50} />
                <p style={{fontSize: 33, fontWeight: 200, color: "#444444"}}>
                  Start your Secure Software Development journey with the 
                  right security building blocks
                </p>
              </div>
            </Col>
            <Col span={10} offset={1}>
              <div className="signup-box">
                <div className="title-wrapper">
                  <LogoSVG className="brand-logo" />
                  <span className="brand-name">Trusthive</span>
                </div>
                <Spacer size={200} />
                <div className="login-hero">
                  <div className="ribbon"> Simple One click </div>
                  <Spacer size={15} />
                  <p style={{fontSize: 36, fontWeight: 200}}>sign up & login</p>
                  <Spacer size={40} />
                  <p style={{fontSize: 21, fontWeight: 200, color: '#777777'}}>Start for free, no credit card required</p>
                </div>
                
                <div className="g-btn-wrapper">
                  <Button size="large" className="google-btn" onClick={() => doGooglelogin()}>
                    <GoogleOutlined />
                    Join with Google
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Login;
