/**
 * Refactor Avatar
 */

import React from "react";
import {
    Avatar as AntAvatar,
    Tooltip
} from 'antd';


const Avatar = (data) => {
    const {userData, tooltip = false} = data;
    return (
        <>
            {tooltip ? 
                <Tooltip title={userData.full_name} placement="bottom">
                    {userData.picture ?  <AntAvatar src={userData.picture} onClick={(e) => e.preventDefault()} /> :
                    <AntAvatar onClick={(e) => e.preventDefault()}>
                        {`${userData.full_name}`.match(/(\b\S)?/g).join("").toUpperCase()}
                    </AntAvatar>
                    }
                </Tooltip> :
                <>
                    {userData.picture ?  <AntAvatar src={userData.picture} onClick={(e) => e.preventDefault()} /> :
                    <AntAvatar onClick={(e) => e.preventDefault()}>
                        {`${userData.full_name}`.match(/(\b\S)?/g).join("").toUpperCase()}
                    </AntAvatar>
                    }
                </>
                
            }
        </>
    )
};

export default Avatar;