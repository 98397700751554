import React,{useState,useEffect} from 'react'
import PageLayout from '../pageLayout/application'
import axios from 'axios';
import {
  Button,
  Col,
  Input,
  Row,
  Typography,
  Table,
  Drawer
} from 'antd';
import {
  PatchQuestionFill
} from 'react-bootstrap-icons';
import Spacer from '../common/spacer';
import API from '../../constants/api';
import { userDataStore } from "../../store/user";
import { PROJECT_TYPE, PROJECT_PHASE } from "../../constants/app";
import useDocumentTitle from "../../utils/pageTitle";

const { Title } = Typography;
const { TextArea } = Input;
const { Column } = Table;
const SecurityChecklist = () => {
  useDocumentTitle("Security Checklist");
  const userData = userDataStore((state) => state.userData);
  const [isChecklistDetailsDrawerOpen, setChecklistDetailsDrawerOpen] = useState(false);
  const [checklistDetailsDrawerTitle, setChecklistDetailsDrawerTitle] = useState("");
  const [checklistFormDrawerTitle, setChecklistFormDrawerTitle] = useState("Add a new Checklist Item");
  const [checklistFormDrawerMode, setChecklistFormDrawerMode] = useState("NEW");
  const [checklistDetail, setChecklistDetail] = useState({});
  const [editChecklistID, setEditChecklistID] = useState("");
  const [isNewChecklistDrawerOpen, setNewChecklistDrawerOpen] = useState(false);
  const [isChecklistLoading, setIsChecklistLoading] = useState(true);

  const [formData, setFormData] = useState({
    checklistTitle: '',
    checklistDescription: '',
    checklistStatus: '',
    checklistPhase: '',
    checklistTemplates:[],
    checklistIntegration:'',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNewChecklistSubmit = () => {
    const newChecklistData = {
      title: formData.checklistTitle,
      description: formData.checklistDescription,
      status: formData.checklistStatus,
      phase: formData.checklistPhase,
      templates: formData.checklistTemplates,
      integration: formData.checklistIntegration,
      organization_id:userData.data.organization_id,
    };

    axios
      .post(API.SECURITY_CHECKLIST, newChecklistData,{headers: {
        "Content-Type": "application/json",
        Authorization: userData.access_token,
      },})
      .then((response) => {
        setNewChecklistDrawerOpen(false);
      })
      .catch((error) => {
        console.error('Error creating Checklist Item', error);
      });
  };

  const handleEditChecklistSubmit = () => {
    const checklistData = {
      title: formData.checklistTitle,
      description: formData.checklistDescription,
      status: formData.checklistStatus,
      phase: formData.checklistPhase,
      templates: formData.checklistTemplates,
      integration: formData.checklistIntegration,
      organization_id:userData.data.organization_id,
    };
    axios
      .put(
        `${API.SECURITY_CHECKLIST}/${editChecklistID}`,
        checklistData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: userData.access_token
          }
        }
      ).then((response) => {
        setNewChecklistDrawerOpen(false);
      })
      .catch((error) => {
        console.error('Error creating Checklist', error);
      });
  };

  const [checklists, setChecklist] = useState([]);

  useEffect(() => {
    axios
    .get(`${API.CHECKLIST}/${userData.data.organization_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: userData.access_token,
        },
      })
      .then((response) => {
        setIsChecklistLoading(false);
        setChecklist(response.data);
      })
      .catch((error) => {
        setIsChecklistLoading(false);
        console.error('Error fetching Checklist', error);
      });
  }, []);

  const showNewChecklistDrawer = () => {
    setNewChecklistDrawerOpen(true);
  };
  
  const handleNewChecklistDrawerClose = () => {
    setNewChecklistDrawerOpen(false);
  };

  const openChecklistDetailsDrawer = (checklist) => {
    setChecklistDetailsDrawerTitle(checklist.title)
    setChecklistDetailsDrawerOpen(true);
    setChecklistDetail(checklist);
  };
  
  const closeChecklistDetailsDrawer = () => {
    setChecklistDetailsDrawerOpen(false);
  };


  const editChecklist = (checklistObj) => {
    const obj = {
      checklistTitle: checklistObj.title,
      checklistDescription: checklistObj.description,
      checklistStatus: checklistObj.status,
      checklistPhase: checklistObj.phase,
      checklistTemplates: checklistObj.templates,
      checklistIntegration:checklistObj.integration,
    }
    setEditChecklistID(checklistObj.id);
    setChecklistFormDrawerMode('EDIT');
    setChecklistFormDrawerTitle('Edit checklist');
    setFormData(obj);
    setNewChecklistDrawerOpen(true);
  }
  return (
<PageLayout>
  <Row>
    <Col span={14}>
      <Title>Security Checklist</Title>
    </Col>
    <Col span={10}>
      <div style={{ textAlign: "right" }}>
        <Button style={{ marginTop: '40px' }} type="primary" onClick={showNewChecklistDrawer}>
          New Checklist Item
        </Button>
      </div>
    </Col>
  </Row>

  <Row>
    <Col span={17}>
      {checklists &&
        <>
          <Title level={5}>{checklists.length} checklist item(s) found</Title>
          <Table
            loading={isChecklistLoading}
            rowKey="id"
            dataSource={checklists}
            className="bordered-table"
          >
            <Column
              title="Checklist"
              dataIndex="title"
              key="title"
              render={(project_title, checklist) => {
                return (<Button type="link" onClick={() => {openChecklistDetailsDrawer(checklist)}} style={{paddingLeft: 0}}>{checklist.title}</Button>);
              }}
            />
            <Column
              title="Phase"
              dataIndex="phase"
              key="phase"
              render={(phase, checklist) => {
                return (<>{PROJECT_PHASE.find(obj => obj.key === checklist.phase).label}</>);
              }}
            />
            <Column
              title="Actions"
              dataIndex="status"
              key="stustus"
              render={(project_title, checklist) => {
                return (<Button onClick={() => {editChecklist(checklist)}}>
                  Edit
                </Button>);
              }}
            />
          </Table>
        </>
        }
    </Col>
    <Col span={6} offset={1}>
      <Spacer size={50} />
      <div className="help-blurb">
        <h3>
          <PatchQuestionFill />
          What are security checklists?
        </h3>
        <p>
          Security checklists allow you to define standards and best practices that each project in your organization should follow.
        </p>
        <p>
          You can define various parameters and the SDLC phase that the checklist item applies to.
        </p>
      </div>
    </Col>
  </Row>

  <Drawer
    title={checklistFormDrawerTitle}
    onClose={handleNewChecklistDrawerClose}
    open={isNewChecklistDrawerOpen}
    width={800}
    className="drawerForm"
  >
      
    <div className="form-field">
      <label>Title <span>(what's this item about)</span></label>
      <Input
        placeholder="Title"
        name="checklistTitle"
        value={formData.checklistTitle}
        onChange={handleInputChange}
      />
    </div>
    <div className="form-field">
      <label>Description <span>describe what's expected from your teams as part of this step</span></label>
      <TextArea rows={4} 
        placeholder="Description"
        name="checklistDescription"
        value={formData.checklistDescription}
        onChange={handleInputChange}
      />
    </div>
    
    <div className="form-field">
      <label>Phase</label>
      {PROJECT_PHASE.map((p, index) => (
        <div
          key={index} 
          className={"icon-option-field " + (p.key === formData.checklistPhase ? 'selected' : '')}
          onClick={() => setFormData({ ...formData, ['checklistPhase']: p.key })}
        >
          <span>{p.icon}</span>
          <label>{p.label}:</label>
          <p>{p.description}</p>
        </div>
      ))}
    </div>
    
    <Spacer size={60} />

    <div className="form-field">
        <label>Select project types this item will apply to <span>(this checklist item will show up for all projects of these type)</span></label>
        {PROJECT_TYPE.map((p, index) => (
          <div
            key={index} 
            className={"icon-option-field option-inline " + (formData.checklistTemplates.includes(p.key) ? 'selected' : '')}
            onClick={() => setFormData(formData => ({
              ...formData,
              checklistTemplates: [...formData.checklistTemplates, p.key]
            }))}
            style={{width: 200}}
          >
            <span>{p.icon}</span>
            <label>{p.label}</label>
          </div>
        ))}
    </div>


    <div className="form-button-bar">
      <Button onClick={handleNewChecklistDrawerClose}>
        Cancel
      </Button>
      {checklistFormDrawerMode === "NEW" && <Button type="primary" onClick={handleNewChecklistSubmit}>
        Submit
      </Button>}
      {checklistFormDrawerMode === "EDIT" && <Button type="primary" onClick={handleEditChecklistSubmit}>
        Submit edits
      </Button>}
    </div>
  </Drawer>

  <Drawer
    title= {checklistDetailsDrawerTitle}
    onClose={closeChecklistDetailsDrawer}
    open={isChecklistDetailsDrawerOpen}
    width={800}

  >
    <>
      <div className='threatBlurb'>
        <Row>
          {/* <Col span={6}>
            <label>Status</label> 
            {checklistDetail.status && <p >{checklistDetail.status}</p>}
          </Col> */}
          <Col span={6}>
            <label>Phase</label> 
            {checklistDetail.phase && <p >{PROJECT_PHASE.find(obj => obj.key === checklistDetail.phase).label}</p>}
          </Col>
        </Row>
        <Spacer size={25} />
        <div>
          <label>Description</label>
          <p>{checklistDetail.description}</p>
        </div>
        <Spacer size={25} />
        {(checklistDetail.templates && checklistDetail.templates.length) &&
          <div>
            <label>Templates</label>
            {checklistDetail.templates.map((o, i) => {
              return (
                <p key={i}>
                  {PROJECT_TYPE.find(obj => obj.key === o).label}
                </p>
              )
            })}
          </div>
        }
        <Spacer size={25} />
        {/* <div>
          <label>Integration</label>
          <p>{checklistDetail.integration}</p>
        </div> */}
      </div>
    </>
  </Drawer>
</PageLayout>
)}

export default SecurityChecklist