import { memo, useState,useEffect } from "react";

const TextIcon = (data) => {
    return (
        <div className="node-icon-text">
            {data.txt}
        </div>
    );
}

export default TextIcon;