import React,{useState} from 'react';
import find from "lodash/find";
import axios from "axios";
import {
  Button,
  Popover,
  Tag,
  Tooltip
} from "antd";
import {
  CheckOutlined,
  FormOutlined
} from '@ant-design/icons';
import API from '../../constants/api';
import TagsInput from './TagsInput';
import { addMetadataTagToOrganization, findAndUpdateByAttribute } from '../../utils/utils';
import { userDataStore } from '../../store/user';


const MetadataPopover = ({
  id,
  projectDetails,
  componentType
}) => {
  const userData = userDataStore(state => state.userData);
  const canvasComponent = componentType === "node" ? find(projectDetails.threat_canvas.nodes, { id: id }) : find(projectDetails.threat_canvas.edges, { id: id });
  const compMetadata = canvasComponent && canvasComponent.metadataTags ? canvasComponent.metadataTags : [];
  const [showMetadataPopover, setShowMetadataPopover] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const defaultFormData = {
    metadataTags: compMetadata
  };
  const [formData, setFormData] = useState(defaultFormData);

  const onMetadataTagsSelectCallback = (tags) => {
    setFormData({ ...formData, ['metadataTags']: tags });
  };

  const handleMetadataSubmit = () => {
    setSubmitting(true);
    findAndUpdateByAttribute(projectDetails, 'id', id, formData);
    axios.put(`${API.UPDATE_PROJECT}/${projectDetails.project_id}`, projectDetails, {
      headers: {
        "Content-Type": "application/json",
        Authorization: userData.access_token
      }
    }).then((response) => {
      setSubmitting(false);
      // updateProjectDetails({...projectDetails, comments});
    })
    .catch((err) => {
      setSubmitting(false);
    });
  };
  
  return (
    <>
      <Tooltip title="Add metadata">
        <Button
          onClick={() => setShowMetadataPopover(true)}
          shape="circle" 
          icon={<FormOutlined />}
        />
      </Tooltip>
      <Popover
        placement="rightTop"
        trigger="click"
        open={showMetadataPopover}
          content={
            <div className="canvasPopover">
              <div className="form-field">
                <strong>Attributes</strong><br /><br />
                <TagsInput
                  onTagSelect={onMetadataTagsSelectCallback}
                  api={API.COMPONENT_METADATA_TAGS}
                  orgId={"community"}
                  newTagCallback={addMetadataTagToOrganization}
                  value={formData.metadataTags}
                />
              </div>
              <div className="buttonWrapper" style={{ marginTop: '8px' }}>
                <Button type="link" onClick={() => setShowMetadataPopover(false)}>
                  Close
                </Button>
                <Button loading={submitting} type="primary" onClick={handleMetadataSubmit}>
                  {!submitting && <CheckOutlined />}
                </Button>
              </div>
            </div>
          }
        />
    </>
  )
}

export default MetadataPopover