/**
 * TODO:
 * - Add filters
 * 
 */

import React, { useState, useEffect } from 'react';
import find from "lodash/find";
import remove from "lodash/remove";
import pull from "lodash/pull";
import parse from 'html-react-parser';
import axios from 'axios';
import {
  Button,
  Col,
  Input,
  Row,
  Select
} from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined
} from "@ant-design/icons";
import API from '../../constants/api';
import { userDataStore } from "../../store/user";
import {THREAT_CATEGORIES, getThreatCategory} from '../../constants/threatCategories';
import { THREAT_ACTORS, getThreatActor } from '../../constants/threatActors';
import { THREAT_RISK, getThreatRisk } from '../../constants/threatRisk';
import { addThreatTagToOrganization } from '../../utils/utils';
import sidebarComponents from '../../data/canvasComponents';
import Spacer from '../common/spacer';
import TagsInput from '../common/TagsInput';

const { TextArea } = Input;
const { Option, OptGroup } = Select;

const ThreatForm = (data) => {
    const { exitThreatForm, onNewThreatSubmit, addThreatToProject, addThreatToOrg, threatData } = data;
    const defaultFormData = {
        threatTitle: threatData && threatData.threat_id ? threatData.threat_title : '',
        threatDescription: threatData && threatData.threat_description ? threatData.threat_description : '',
        threatSummary: threatData && threatData.threat ? threatData.threat : '',
        threatCategories: threatData && threatData.threat_categories ? threatData.threat_categories : [],
        threatActor: threatData && threatData.threat_actor ? threatData.threat_actor : '',
        threatRisk: threatData && threatData.risk ? threatData.risk : '',
        threatMitigationList: threatData && threatData.mitigation_list ? threatData.mitigation_list : [],
        threatTags: threatData && threatData.tags ? threatData.tags : [],
        threatComponents: threatData && threatData.components ? threatData.components : null,
    }

  const [formData, setFormData] = useState(defaultFormData);

  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
  };

  const onTagsSelectCallback = (tags) => {
    setFormData({ ...formData, ['threatTags']: tags });
  }

  const handleSelectChange = (data, fieldName) => {
    setFormData({ ...formData, [fieldName]: data });
  }

  const handleMitigationListChange = (e) => {
    const { name, value } = e.target;
    const newObj = [...formData.threatMitigationList];    
    find(newObj, {'id': Number(name)}).title = value;
    setFormData({ ...formData, ['threatMitigationList']: newObj });
  };

  const addMitigationPlan = () => {
    const obj = {
      id: Math.floor(1000 + Math.random() * 9000),
      title: ""
    };
    const newObj = [...formData.threatMitigationList];
    newObj.push(obj);
    setFormData({ ...formData, ['threatMitigationList']: newObj });
  }

  const removeMitigationPlan = (item) => {
    const newObj = [...formData.threatMitigationList];
    remove(newObj, { id: item.id });
    setFormData({ ...formData, ['threatMitigationList']: newObj });
  }

  const handleNewThreatSubmit = () => {
    const threatData = {
      threat_title: formData.threatTitle,
      threat_description: formData.threatDescription,
      threat: formData.threatSummary,
      threat_categories: formData.threatCategories,
      threat_actor: formData.threatActor,
      risk: formData.threatRisk,
      mitigation_list: formData.threatMitigationList,
      organization_id: 'community', //TODO: needs to be dynamic
      tags: formData.threatTags,
      components: formData.threatComponents
    };

    onNewThreatSubmit(threatData);
    setFormData(defaultFormData);
    exitThreatForm();


    
    // Add this threat to User's org pending
    // if(addThreatToOrg) {
    //     axios
    //     .post(
    //         API.CREATE_THREAT_IN_LIBRARY,
    //         newThreatData,
    //         {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: userData.access_token
    //         }
    //         }
    //     ).then((response) => {
    //         console.log('Threat created successfully', response);
    //         // onNewThreatSuccess do something
    //     })
    //     .catch((error) => {
    //         console.error('Error creating threat', error);
    //     });
    // }
  };

  useEffect(() => {
    setFormData(defaultFormData);
  }, [threatData]);

  
  return (
      <div className="drawerForm">
        <div className="form-field">
          <label>Threat Title</label>
          <Input
            placeholder="Threat Title"
            name="threatTitle"
            value={formData.threatTitle}
            onChange={handleInputChange}
          />
        </div>
        
        <div className="form-field">
          <label>Threat Description</label>
          <TextArea rows={4} 
            placeholder="Threat Description"
            name="threatDescription"
            value={formData.threatDescription}
            onChange={handleInputChange}
          />
        </div>
        
        <Row>
          <Col span={24}>
            <div className="form-field">
              <label>Threat Categories</label> 
              <Select
                optionLabelProp="label"
                style={{ width: "100%" }}
                mode="multiple"
                name="threatCategories"
                placeholder="Threat Categories"
                onChange={(data) => handleSelectChange(data, "threatCategories")}
                value={formData.threatCategories}
              >
                {THREAT_CATEGORIES.map(item => (
                  <Option key={item.id} value={item.id} label={item.title}>
                      {item.title}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <div className="form-field">
              <label>Threat Tags</label>
              <TagsInput
                onTagSelect={onTagsSelectCallback}
                api={API.THREAT_TAGS}
                orgId={'community'}
                newTagCallback = {addThreatTagToOrganization}
                value={formData.threatTags}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div className="form-field">
              <label>Risk</label>
              <Select
                  optionLabelProp="label"
                  style={{ width: "100%" }}
                  mode="single"
                  name="threatRisk"
                  placeholder="Select a threat risk"
                  onChange={(data) => handleSelectChange(data, "threatRisk")}
                  value={formData.threatRisk}
                >
                {THREAT_RISK.map(item => (
                  <Option key={item.id} value={item.id} label={item.id} />
                ))}
              </Select>
            </div>
          </Col>
          <Col span={11} offset={1}>
            <div className="form-field">
                <label>Threat Actors</label>
                <Select
                  optionLabelProp="label"
                  style={{ width: "100%" }}
                  mode="single"
                  name="threatActor"
                  placeholder="Select a threat actor"
                  onChange={(data) => handleSelectChange(data, "threatActor")}
                  value={formData.threatActor}
                >
                  {THREAT_ACTORS.map(item => (
                    <Option key={item.id} value={item.id} label={item.title}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="form-field">
              <label>Affected components</label>
              <Select
                  optionLabelProp="value"
                  style={{ width: "100%" }}
                  mode="multiple"
                  name="threatComponents"
                  placeholder="Select one or more components affected by this threat"
                  onChange={(data) => handleSelectChange(data, "threatComponents")}
                  value={formData.threatComponents}
                >
                  {sidebarComponents.map(group => (
                    <OptGroup key={group.groupTitle} label={group.groupTitle}>
                    {group.components.map(item => (
                        <Option key={item.key} value={item.key} label={item.title}>
                          {item.title}
                        </Option>
                      ))}
                    </OptGroup>
                  ))}
                </Select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="form-field">
              <label>Mitigation List</label>
              <Button type="default" className="btn-outlined" icon={<PlusOutlined />} onClick={addMitigationPlan}>
                Add Mitigation Plan
              </Button>
              <Spacer size={20} />
              {formData.threatMitigationList &&
              <>
                {formData.threatMitigationList.map((item, index) => (
                    <div key={index}>
                    <Row key={index}>
                      <Col span={22}>
                        <Input
                          placeholder="Threat Mitigation List"
                          name={item.id}
                          value={item.title}
                          onChange={handleMitigationListChange}
                        />
                      </Col>
                      <Col>
                        <Button type="link" icon={<MinusCircleOutlined />} onClick={() => removeMitigationPlan(item)} />
                      </Col>
                    </Row>
                    <Spacer size={20} />
                    </div>
                ))}
              </>
              }
            </div>
          </Col>
        </Row>
        
        <div className="form-button-bar">
          <Button onClick={exitThreatForm}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleNewThreatSubmit}>
            Submit
          </Button>
        </div>
      </div>
  );
};

export default ThreatForm;