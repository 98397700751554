/**
 * TODO:
 * - Dedup the collab list
 * 
 */


import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Alert,
  AutoComplete,
  Button,
  Col,
  Input,
  List,
  Modal,
  message,
  Row,
  Select,
  Table,
  Tag,
  Typography,
  Tabs,
} from "antd";
import {
  SearchOutlined
} from "@ant-design/icons";
import API from "../../constants/api"
import Avatar from "../common/Avatar";
import { userDataStore } from "../../store/user";


const { Option } = Select;
const userOptionRender = (user) => {
    return (
        <Row>
            <Col><Avatar userData={user} /></Col>
            <Col><p style={{verticalAlign: 'middle', padding: '5px 15px', margin: 0}}>{user.full_name}</p></Col>
        </Row>
    )
}


const ShareModal = ({projectDetails, isShareModalOpen, setShareModalOpen, saveProjectDetailsObjects}) => {
    const [messageApi, contextHolder] = message.useMessage();
    const userData = userDataStore((state) => state.userData);
    const [userOptions, setuserOptions] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [userSearchValue, setUserSearchValue] = useState('');

    const onUserSelect = (value, inst) => {
        selectedUsers.push(inst);
        setSelectedUsers(selectedUsers);
        setUserSearchValue('');
    };

    const removeSelectedUser = (user) => {
        selectedUsers.splice(selectedUsers.findIndex(function(i){
            return i.userObj.user_id === user.userObj.user_id;
        }), 1);
        setSelectedUsers(selectedUsers);
    };

    const handleSearch = async (query) => {
        setUserSearchValue(query)
        const organization_id = "community"; // TODO: THIS NEEDS DTO BE DYNAMIC
        if(query !== '') {
            await axios.get(`${API.SEARCH_USER}/${organization_id}/${query}`, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: userData.access_token
                }
              }).then((response) => {
                const formattedResults = response.data.map(user => ({
                  value: user.user_id,
                  label: userOptionRender(user),
                  userObj: user
                }));
                setuserOptions(formattedResults);
              }).catch((err) => {
                messageApi.open({
                  type: 'error',
                  content: `There was an error: ${err}`,
                  className: 'error-message',
                  duration: 10
                });
              });
        }
    }

    const addCollaborators= () => {
        let projectCollaborators = [];
        
        /** NORMALIZE COLLABORATORS */
        selectedUsers.forEach(user => {
            const collabObj = {
                "user_id": user.userObj.user_id,
                "full_name": user.userObj.full_name,
                "picture": user.userObj.picture,
                "access": "EDIT",
            };
            projectCollaborators.push(collabObj);
        });
        
        projectDetails.collaborators = projectCollaborators;

        saveProjectDetailsObjects("collaborators", projectCollaborators, () => {
            setShareModalOpen(false)
            messageApi.open({
                type: 'success',
                content: 'Collaboratos added to the project',
                className: 'success-message',
                duration: 5
            });
        }, () => {
            setShareModalOpen(true)
            messageApi.open({
                type: 'error',
                content: `There was an error`,
                className: 'error-message',
                duration: 10
            });
        });
    }


    return(
        <>
            {contextHolder}
            <Modal
            title="Share Project"
            open={isShareModalOpen}
            onOk={addCollaborators}
            okButtonProps={{
                type: "primary",
            }}
            onCancel={() => setShareModalOpen(false)}
            width={800}
            >
                <div className="form-field">
                    <label>Add People</label>
                    <Row>
                        <Col span={16}>
                            <div className="autoCompleteInput">
                                <AutoComplete
                                    options={userOptions}
                                    onSelect={onUserSelect}
                                    onSearch={(text) => handleSearch(text)}
                                    placeholder="Add by name or email"
                                    allowClear={true}
                                    value= {userSearchValue}
                                />
                                <SearchOutlined />
                            </div>
                        </Col>
                        <Col span={7} offset={1}>
                            <Select defaultValue="FULL_ACCESS" className="add-on-select">
                                <Option key="FULL_ACCESS" value="FULL_ACCESS">
                                    <strong>Full Access</strong><br />
                                    <span className="font-small">View, edit and share</span>
                                </Option>
                                <Option key="COMMENT_ACCESS" value="COMMENT_ACCESS">
                                    <strong>Comment</strong><br />
                                    <span className="font-small">Can only read and comment</span>
                                </Option>
                                <Option key="VIEW_ACCESS" value="VIEW_ACCESS">
                                    <strong>Read Only</strong><br />
                                    <span className="font-small">Cannot edit, comment or share</span>
                                </Option>
                            </Select>
                        </Col>
                    </Row>
                    <div className="userTags">
                    {selectedUsers.map(function (user, index) {
                        return (
                            <Tag color="purple" key={user.user_id} closable={true} onClose={() => removeSelectedUser(user)}>
                                {user.userObj.full_name}
                            </Tag>
                        )
                    })}

                    </div>
                </div>
                
                <div className="border-spacer"></div>

                <div className="form-field">
                    <label>Shareable Link</label>
                    <Input
                        addonAfter={<Button type="link" style={{height: '30px'}}>Copy link</Button>} 
                        placeholder={window.location.href}
                        disabled
                    />
                </div>

                
            </Modal>
        </>
    )
};

export default ShareModal;