import React, { useState } from "react";
import "./sidebar.css";
import { DatabaseOutlined, SearchOutlined } from "@ant-design/icons";
import { Collapse, Input } from "antd";
import sidebarComponents from "../../data/canvasComponents";

const { Panel } = Collapse;

const onDragStart = (event, nodeType) => {
  const data = { nodeType };
  event.dataTransfer.setData("application/json", JSON.stringify(data));
  event.dataTransfer.effectAllowed = "move";
};

const Sidebar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [activePanels, setActivePanels] = useState(['GENERAL_COMPONENTS', 'TRUST_BOUNDARY' ,'AI_ML']); // State to store the active panels

  const handleSearch = (value) => {
    setSearchQuery(value);

    // Only set active panels if there is a search query
    if (value.trim() !== "") {
      const newActivePanels = sidebarComponents
        .filter((componentGroup) =>
          componentGroup.components.some((component) =>
            component.title.toLowerCase().includes(value.toLowerCase())
          )
        )
        .map((componentGroup) => componentGroup.group);

      setActivePanels(newActivePanels);
    } else {
      setActivePanels([]); // No search query, so collapse all panels
    }
  };

  // Handle panel toggle (click) event
  const handlePanelToggle = (activeKey) => {
    setActivePanels(activeKey);
  };

  return (
    <aside
      style={{
        width: "300px",
        height: "100%",
        background: "#EEEEEE",
        borderRight: "1px solid #CCCCCC",
        flexDirection: "column",
        padding: "15px 10px",
        fontSize: "12px",
        overflowY: "auto",
      }}
    >
      <Input
        className="search-bar"
        placeholder="Search components"
        prefix={<SearchOutlined />}
        style={{ marginBottom: 20 }}
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
      />

      {sidebarComponents.map(function (componentGroup) {
        return (
          <Collapse
            key={componentGroup.group}
            expandIconPosition="end"
            ghost
            className="sidebarGroup"
            activeKey={activePanels} // Set active panels
            onChange={handlePanelToggle} // Handle panel toggle event
          >
            <Panel
              className="panel-class"
              header={componentGroup.groupTitle}
              key={componentGroup.group}
            >
              {componentGroup.components.map(function (component) {
                if (
                  component.title
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                ) {
                  return (
                    <div
                      className="one-node"
                      key={component.key}
                      draggable
                      onDragStart={(event) => onDragStart(event, component)}
                    >
                      <div className="dndnode">
                        <span className="nodeIcon">{component.icon}</span>
                      </div>
                      <span className="nodeTitle">{component.title}</span>
                    </div>
                  );
                }
                return null;
              })}
            </Panel>
          </Collapse>
        );
      })}
    </aside>
  );
};

export default Sidebar;
