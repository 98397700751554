/**
 * TODO:
 * - first node on canvas never gets deleted
 * 
 */


import React, { useState, useEffect } from 'react';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import axios from "axios";
import {useParams} from 'react-router-dom';
import {
  message
} from 'antd';
import PageLayout from '../pageLayout/projectDetailsLayout';
import Canvas from '../canvas/canvas';
import API from "../../constants/api";
import { userDataStore } from "../../store/user";
import useDocumentTitle from '../../utils/pageTitle';

const ProjectDetails = () => {
  useDocumentTitle("Project Details");
  const [messageApi, contextHolder] = message.useMessage();
  const [projectDetails, updateProjectDetails] = useState([]);
  const [isProjectSaving, setIsProjectSaving] = useState(false);
  const [recommendedThreats, setRecommendedThreats] = useState([]);
  const [isPageDirty, setPageDirty] = useState(false);
  const userData = userDataStore((state) => state.userData);
  const {projectId} = useParams();
  
  /** GET PROJECTS DETAILS */
  useEffect(() => {
    axios.get(API.PROJECT_DETAILS, {
      params:{
        project_id: projectId,
        organization_id: userData.data.organization_id
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: userData.access_token
      }
    }).then((response) => {
      const {data} = response;
      const { recommended_threats } = data;
      updateProjectDetails(data);
      setRecommendedThreats(recommended_threats);
    }).catch((err) => {
      messageApi.open({
        type: 'error',
        content: `There was an error: ${err}`,
        className: 'error-message',
        duration: 10
      });
    });

    const onBeforeUnload = (e) => {
      if (isPageDirty) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  const saveProjectDetails = () => {
    setIsProjectSaving(true);
    const isCurrentUserCollaborator = find(projectDetails.collaborators, { user_id: userData.data.user_id }) ? true : false;
    if(!isCurrentUserCollaborator) {
      const o = {
        "user_id": userData.data.user_id,
        "full_name": userData.data.full_name,
        "picture": userData.data.picture,
        "access": "EDIT",
      }
      projectDetails.collaborators.push(o);
    }
    
    axios.put(`${API.UPDATE_PROJECT}/${projectId}`, projectDetails, {
      headers: {
        "Content-Type": "application/json",
        Authorization: userData.access_token
      }
    }).then((response) => {
      setPageDirty(false);
      setIsProjectSaving(false);
      updateProjectDetails(response.data.project_data);
      messageApi.open({
        type: 'success',
        content: 'All changes saved successfully.',
        className: 'success-message',
        duration: 5
      });
    }).catch((err) => {
      setIsProjectSaving(false);
      messageApi.open({
        type: 'error',
        content: `There was an error: ${err}`,
        className: 'error-message',
        duration: 10
      });
    });
  };

  const saveProjectDetailsObjects = (param, obj, successCallback, errorCallback) => {
    axios.put(`${API.UPDATE_PROJECT}/${projectId}`, {
      [param]: obj
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: userData.access_token
      }
    }).then((response) => {
      updateProjectDetails(response.data.project_data);
      if(successCallback) {
        successCallback();
      }      
    }).catch((err) => {
      console.log('There was an error' + err);
      if(errorCallback) {
        errorCallback();
      }
    });
  };

  // Threat Intelligence
  const doThreatRecommendations = (node) => {
    axios.get(API.THREAT_INTEL, {
      params:{
        organization_id: userData.data.organization_id,
        component: node.data.key
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: userData.access_token
      }
    }).then((response) => {
      if(response.data.length > 0) {
        const newObj = [...recommendedThreats];
        response.data.forEach((o) => {
          newObj.push(o);
        });
        setRecommendedThreats(uniqBy(newObj, 'threat_id'));
        saveProjectDetailsObjects('recommended_threats', uniqBy(newObj, 'threat_id'));
      }
    }).catch((err) => {
      console.log('An error happened' + err);
    });
  }

  useDocumentTitle(`Project Details | ${projectDetails.project_title}`);

  return (
    <>
      {projectDetails && projectDetails.project_id ? 
        <>
          {contextHolder}
          <PageLayout
            projectDetails={projectDetails}
            updateProjectDetails={updateProjectDetails}
            saveProjectDetails={saveProjectDetails}
            isProjectSaving = {isProjectSaving}
            recommendedThreats={recommendedThreats}
            saveProjectDetailsObjects={saveProjectDetailsObjects}
            isPageDirty={isPageDirty}
          >
            {projectDetails && projectDetails.threat_canvas &&
              <Canvas
                projectDetails={projectDetails}
                updateProjectDetails={updateProjectDetails}
                doThreatRecommendations={doThreatRecommendations}
                saveProjectDetails={saveProjectDetails}
                saveProjectDetailsObjects={saveProjectDetailsObjects}
                setPageDirty={setPageDirty}
              />
            }
          </PageLayout>
        </> : 
        <>
        </>
      }
      
    </>
  );
};

export default ProjectDetails;