import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import "./login.css";
import { userDataStore } from "../../store/user";

const Logoff = () => {
  const navigate = useNavigate();
  const userData = userDataStore((state) => state.userData);
  const logoutCurrentUser = userDataStore((state) => state.logoutCurrentUser);
  if(userData.data) {
    logoutCurrentUser();
  }
  
  return (
    <>
      <Navigate replace to={"/login"} />
    </>
  );
};

export default Logoff;
