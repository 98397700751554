import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import filter from "lodash/filter";
import { ReactComponent as LogoSVG } from '../../assets/brand-logo.svg';
import moment from 'moment';
import {
  ArrowLeftOutlined,
  BellOutlined,
  CheckCircleOutlined,
  CommentOutlined,
  EllipsisOutlined,
  FormOutlined,
  SettingOutlined,
  ShareAltOutlined,
  SecurityScanOutlined,
  WarningOutlined
} from "@ant-design/icons";
import {
  Avatar as AntAvatar,
  Badge,
  Button,
  Col,
  Dropdown,
  Layout,
  Row,
  Space,
  theme,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import DocDrawer from "../canvas/docDrawer";
import CommentsDrawer from "../canvas/commentsDrawer";
import ThreatsDrawer from "../canvas/threatsDrawer";
import ChecklistDrawer from "../canvas/checklistDrawer";
import ShareModal from "../canvas/shareModal";
import ProjectStatusModal from "../projects/projectStatusModal";
import { getBadgeColor } from "../../utils/utils";
import Avatar from "../common/Avatar";
import TerraformDrawer from "../canvas/terraformDrawer";
import { CaretRight, CaretRightFill, Code } from "react-bootstrap-icons";
import Feedback from '../common/Feedback';
import {userDataStore} from '../../store/user';

const { Header, Content } = Layout;
const { Title } = Typography;

const PageLayout = ({
  children,
  projectDetails,
  updateProjectDetails,
  saveProjectDetails,
  isProjectSaving,
  recommendedThreats,
  saveProjectDetailsObjects,
  isPageDirty
}) => {
  const userData = userDataStore(state => state.userData);
  const [openDocDrawer, setOpenDocDrawer] = useState(false);
  const [openCommentsDrawer, setOpenCommentsDrawer] = useState(false);
  const [openThreatsDrawer, setOpenThreatsDrawer] = useState(false);
  const [openChecklistDrawer, setOpenChecklistDrawer] = useState(false);
  const [isTerraformDrawerOpen, setTerraformDrawerOpen] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [isProjectStatusModalOpen, setProjectStatusModalOpen] = useState(false);
  const recommendedThreatsCount = recommendedThreats.filter(t => { return t.status == undefined; });

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const showDocDrawer = () => {
    setOpenDocDrawer(true);
  };
  const onCloseDocDrawer = () => {
    setOpenDocDrawer(false);
  };

  const showCommentsDrawer = () => {
    setOpenCommentsDrawer(true);
  };
  const onCloseCommentsDrawer = () => {
    setOpenCommentsDrawer(false);
  };

  const showThreatsDrawer = () => {
    setOpenThreatsDrawer(true);
  };
  const onCloseThreatsDrawer = () => {
    setOpenThreatsDrawer(false);
  };

  const showChecklistDrawer = () => {
    setOpenChecklistDrawer(true);
  };
  const onCloseChecklistDrawer = () => {
    setOpenChecklistDrawer(false);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ padding: 0, background: colorBgContainer, borderBottom: '1px solid rgb(204, 204, 204)' }}>
        <Row>
          <Col span={1}>
            <div style={{ margin: "10px 0 10px 15px", verticalAlign: "middle" }}>
              <LogoSVG />
            </div>
          </Col>
          <Col span={10}>
            <div style={{ padding: "15px 25px 0 15px" }}>
              <Title level={3} style={{ margin: "0" }}>
                <Link to="/projects">
                  <Button type="text" style={{ marginRight: "15px" }}>
                    <ArrowLeftOutlined />
                  </Button>
                </Link>
                {projectDetails && projectDetails.project_title ? (
                  <Tag
                    color={getBadgeColor(projectDetails.project_status)}
                    style={{ margin: "0 15px 0 0", verticalAlign: "middle", cursor: "pointer"}}
                    onClick={() => setProjectStatusModalOpen(true)}
                  >
                    {projectDetails && projectDetails.project_status ? projectDetails.project_status.toUpperCase() : ''}
                    <CaretRightFill style={{verticalAlign: 'middle'}} />
                  </Tag>
                ) : (
                  <></>
                )}
                {projectDetails && projectDetails.project_title
                  ? projectDetails.project_title
                  : ''}
                <span className="saveTimestamp">Saved {moment(projectDetails.last_modified_on_date).fromNow()}</span>
              </Title>
            </div>
          </Col>
          <Col align="right" span={6}>
                  {isPageDirty &&
                    <span style={{color: 'rgb(212, 83, 6)', fontStyle: 'italic', fontWeight: 400}}>
                      <WarningOutlined /> There are unsaved changes. Please click on Save Project
                    </span>
                  }
          </Col>
          <Col align="right" span={4}>
            <Space size={20}>
              {projectDetails.collaborators &&
                <div style={{paddingTop: 10}}>
                  <AntAvatar.Group>
                    {projectDetails.collaborators.map(function (user, index) {
                        return (
                          <Avatar key={index} userData={user} tooltip={true} />
                        )
                    })}
                  </AntAvatar.Group>
                </div>
              }
              <Tooltip placement="bottom" title={"Project details"}>
                <Button
                  onClick={() => setTerraformDrawerOpen(true)}
                  style={{ border: 0, padding: 0 }}
                >
                  <Code style={{ fontSize: "21px" }} />
                </Button>
              </Tooltip>
              <Tooltip placement="bottom" title={"Project details"}>
                <Button
                  onClick={showDocDrawer}
                  style={{ border: 0, padding: 0 }}
                >
                  <FormOutlined style={{ fontSize: "21px" }} />
                </Button>
              </Tooltip>
              <Tooltip placement="bottom" title={"Security Checklist"}>
                <Button
                  onClick={showChecklistDrawer}
                  style={{ border: 0, padding: 0 }}
                >
                  <CheckCircleOutlined style={{ fontSize: "21px" }} />
                </Button>
              </Tooltip>
              <Tooltip placement="bottom" title={"Threats"}>
                <Badge count={recommendedThreatsCount.length}>
                  <Button
                    onClick={showThreatsDrawer}
                    style={{ border: 0, padding: 0 }}
                  >
                    <SecurityScanOutlined style={{ fontSize: "21px" }} />
                  </Button>
                </Badge>
              </Tooltip>
              <Tooltip placement="bottom" title={"Comments"}>
                <Button
                  onClick={showCommentsDrawer}
                  style={{ border: 0, padding: 0 }}
                >
                  <CommentOutlined style={{ fontSize: "21px" }} />
                </Button>
              </Tooltip>
              <Tooltip placement="bottom" title={"Share"}>
                <Button
                  onClick={() => setShareModalOpen(true)}
                  style={{ border: 0, padding: 0 }}
                >
                  <ShareAltOutlined style={{ fontSize: "21px" }} />
                </Button>
              </Tooltip>
              {/* <Tooltip placement="bottom" title={"Notifications"}>
                <BellOutlined style={{ fontSize: "21px" }} />
              </Tooltip>
              <Tooltip placement="bottom" title={"Settings"}>
                <SettingOutlined style={{ fontSize: "21px" }} />
              </Tooltip> */}
            </Space>
          </Col>
          <Col span={3} align="center">
            <Space size={20}>
              <Button
                type="primary"
                onClick={saveProjectDetails}
                loading={isProjectSaving}
              >
                  {isProjectSaving ? `Saving project` : `Save project`}
              </Button>
              <Button
                className="btn-outlined btn-more-rounded"
                onClick={() => setProjectStatusModalOpen(true)}
              >
                  <EllipsisOutlined />
              </Button>
            </Space>
          </Col>
        </Row>
      </Header>
      <Content>
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            flexGrow: 1,
            height: "calc(100vh - 68px)",
          }}
        >
          {children}
        </div>
      </Content>
      {projectDetails.project_description && <DocDrawer
        onClose={onCloseDocDrawer}
        open={openDocDrawer}
        projectDetails={projectDetails}
        updateProjectDetails={updateProjectDetails}
      />}
      {projectDetails.comments && <CommentsDrawer
        open={openCommentsDrawer}
        onClose={onCloseCommentsDrawer}
        projectDetails={projectDetails}
        updateProjectDetails={updateProjectDetails}
        saveProjectDetails={saveProjectDetails}
        saveProjectDetailsObjects={saveProjectDetailsObjects}
      />}
      <ThreatsDrawer
        open={openThreatsDrawer}
        onClose={onCloseThreatsDrawer}
        projectDetails={projectDetails}
        recommendedThreats={recommendedThreats}
        saveProjectDetailsObjects={saveProjectDetailsObjects}
      />
      <ChecklistDrawer
        onClose={onCloseChecklistDrawer}
        open={openChecklistDrawer}
        projectDetails={projectDetails}
        saveProjectDetailsObjects={saveProjectDetailsObjects}
      />
      <ShareModal
        projectDetails={projectDetails}
        isShareModalOpen={isShareModalOpen}
        setShareModalOpen={setShareModalOpen}
        saveProjectDetailsObjects={saveProjectDetailsObjects}
      />
      <ProjectStatusModal
        projectDetails={projectDetails}
        isModalOpen={isProjectStatusModalOpen}
        setModalOpen={setProjectStatusModalOpen}
        saveProjectDetailsObjects={saveProjectDetailsObjects}
      />
      <TerraformDrawer
        open={isTerraformDrawerOpen}
        onClose={() => setTerraformDrawerOpen(false)}
        projectDetails={projectDetails}
        saveProjectDetailsObjects={saveProjectDetailsObjects}
      />
      <Feedback user={userData.data} />
    </Layout>
  );
};

export default PageLayout;
