import React, { useState } from 'react';
import { Link, useLocation, Navigate, useNavigate } from "react-router-dom";
import { ReactComponent as LogoSVG } from '../../assets/brand-logo.svg';
import {
    BarsOutlined,
    BellOutlined,
    HomeOutlined,
    LogoutOutlined,
    ProjectOutlined,
    QuestionCircleOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    TeamOutlined,
    UserOutlined,
    FolderOpenOutlined,
    ScheduleOutlined,
    SettingOutlined
  } from '@ant-design/icons';
import {
    Button,
    Col,
    Dropdown,
    Input,
    Layout,
    Menu,
    Row,
    Space,
    Tag,
    theme
} from 'antd';
import Avatar from "../common/Avatar";
import {userDataStore} from '../../store/user';
import "./application.css";
import { isUserAuthorized } from "../../utils/utils";
import Feedback from '../common/Feedback';

const { Header, Content, Sider } = Layout;

const PageLayout = ({ children }) => {
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const userData = userDataStore(state => state.userData);
    const logoutCurrentUser = userDataStore(state => state.logoutCurrentUser);
    const location = useLocation();
    const profileDropdown = [
        {
          label: <Button type="link" icon={<UserOutlined />}  >{userData.data && <span>{userData.data.full_name}</span>}</Button>,
          key: '0',
        },
        {
          label: <Button type="link" icon={<BarsOutlined />}  >Preferences</Button>,
          key: '1',
        },
        {
          type: 'divider',
        },
        {
          label: <Button type="link" icon={<LogoutOutlined />}  onClick={() => logoutCurrentUser()}>Logout</Button>,
          key: '3',
        },
    ];

    if(!isUserAuthorized(userData)) {
        navigate('/wrong-turn');
    }

    return (
    <>
        {userData.data ? (
            <>
                {userData.data.onboarded ? (
                    <Layout style={{ minHeight: '100vh' }}>
                        <Sider trigger={null} style={{ backgroundColor:"black",paddingLeft:"15px",paddingRight:"15px"}} width={246} className ="project-sider" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                            <div style={{ backgroundColor:"black",border:"none",paddingTop:"20px"}} className="logoBar">
                                <div style={collapsed ? {} : {display:"inline-block", width:"50px",height:"50px", verticalAlign: "middle"} } >
                                    <LogoSVG />
                                </div>
                                <span style={collapsed ? { display: 'none'} : {color:"white",fontWeight: 600, fontSize: 24,padding:"10px 0 0 5px"} }>Trusthive</span>
                            </div>
                            
                            <Menu className="site-left-nav" style={{backgroundColor: "black",paddingTop:"30px"}}  defaultSelectedKeys={location.pathname} mode="inline">
                                <Menu.Item key="/">
                                    <Link to="/">
                                        <HomeOutlined style={{color: "white"}} />
                                        <span className='menu-title'>Home</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/projects">
                                    <Link to="/projects">
                                        <ProjectOutlined style={{color: "white"}} />
                                        <span className='menu-title'>Projects</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/directory">
                                    <Link to="/directory">
                                        <TeamOutlined style={{color: "white"}} />
                                        <span className='menu-title'>Directory</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/threat-library/trusthive">
                                    <Link to="/threat-library/trusthive">
                                        <FolderOpenOutlined style={{color: "white"}} />
                                        <span className='menu-title'>Threat library</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="/security-checklist">
                                    <Link to="/security-checklist">
                                        <ScheduleOutlined style={{color: "white"}} />
                                        <span className='menu-title'>Security Checklist</span>
                                    </Link>
                                </Menu.Item>
                            </Menu>

                            <div className="menu-org-name">
                                <Link to="/workspace">
                                    {collapsed ?
                                        <Button to="/workspace" type="link" icon={<SettingOutlined />}>
                                            {`${userData.data.organization_name}`.match(/(\b\S)?/g).join("").toUpperCase()}
                                        </Button> : 
                                        <Button type="link" icon={<SettingOutlined />}  >
                                            {userData.data.organization_name}
                                        </Button>
                                    }
                                </Link>
                            </div>
                        </Sider>
                        <Layout className="site-layout">
                            <Header style={{ padding: 0, background: '#f5f5f5', borderBottom: 0 }}>
                                <Row>
                                    <Col span={10}>
                                        <Button
                                            type="text"
                                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                            onClick={() => setCollapsed(!collapsed)}
                                            className="main-nav-trigger"
                                        />
                                        {/* <div style={{ padding: '0px 25px'}}>
                                            <Input size="large" placeholder="Type to search" prefix={<SearchOutlined />} />
                                        </div> */}
                                    </Col>
                                    <Col span={1} offset={12}>
                                        <Space size={20}>
                                            <QuestionCircleOutlined style={{ fontSize: '21px'}} />
                                            <BellOutlined style={{ fontSize: '21px'}} />
                                        </Space>
                                    </Col>
                                    <Col span={1}>
                                        <div style={{ margin: '-4px 0px 0 10px'}}>
                                            <Dropdown menu={{ items: profileDropdown }} trigger={['click']}>
                                                <Button onClick={(e) => e.preventDefault()} type="link">
                                                    <Avatar userData={userData.data} />
                                                </Button>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </Header>
                            <Content  style={{ margin: '0 16px' }}>
                                <div className='bodyContent'>
                                    {children}
                                </div>
                            </Content>
                        </Layout>
                        <Feedback user={userData.data} />
                    </Layout>
                ) : (
                    <Navigate replace to={"/wrong-turn"} />
                )}
            </>
            ) : (
                <Navigate replace to={"/login"} />
            )
        }
    </>
)};

export default PageLayout;
