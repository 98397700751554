import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {
  Alert,
  Button,
  Col,
  Drawer,
  Input,
  Modal,
  message,
  Row,
  Select,
  Table,
  Tag,
  Typography,
  Tabs,
} from "antd";

const DocDrawer = ({ onClose, open, projectDetails, updateProjectDetails }) => {
  const [projectDescription, setProjectDescription] = useState(projectDetails.project_description);
  
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["clean"],
  ];

  const updateProjectDescription = (value) => {
    setProjectDescription(value);
    updateProjectDetails({...projectDetails, project_description: value});
  }

  return (
    
    <Drawer
      title="Project Details"
      placement="right"
      onClose={onClose}
      open={open}
      size={"large"}
      mask={false}
      rootClassName="page-drawer"
    >
        {projectDescription && 
        <ReactQuill
          theme="snow"
          modules={{ toolbar: toolbarOptions }}
          value={projectDescription}
          onChange={(value) => updateProjectDescription(value)}
        />}
    </Drawer>
  );
};
export default DocDrawer;
