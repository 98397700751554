import React,{useState} from 'react'
import { Button,Popover,Input,List } from "antd";
import { userDataStore } from '../../store/user';
import API from '../../constants/api';
import axios from 'axios';

import { SendOutlined } from '@ant-design/icons';
import _ from "lodash"; 
const { TextArea } = Input;
const CommentPopover = ({
    id,
    showCommentPopover,
    setShowCommentPopover,
    projectDetails,
    saveProjectDetailsObjects,
    comments,
    nodeText
  }) => {
  const [commentText, setCommentText] = useState('');
  const userData = userDataStore(state => state.userData);
  const handleCommentSubmit = () => {
    const nodeCommentObj = {
      "user": {
        "user_id": userData.data.user_id,
        "full_name": userData.data.full_name,
        "picture": userData.data.picture,
      },
      "message": {
        "body": commentText,
      },
      "created_date": new Date().toUTCString(),
      "component": {
        "id": id,
        "title": nodeText,
      },
    };

    comments.push(nodeCommentObj);
    saveProjectDetailsObjects("comments", comments, () => {
      setCommentText('');
    }, () => {
      console.log('There was an error while adding comment');
    });
  };
  return (
    <>
      <Popover
          placement="rightTop"
          trigger="click"
          open={showCommentPopover}
          content={
            <div className="canvasPopover">
              <strong>Comments</strong><br /><br />
              <List itemLayout="vertical" className="comments">
                {_.filter(comments, { 'component': { 'id': id } }).map((comment, index) => (
                  <List.Item key={index}>
                    <List.Item.Meta
                      title={comment.user.full_name}
                    />
                    <p style={{ whiteSpace: 'pre-wrap' }}>{comment.message.body}</p>
                  </List.Item>
                ))}
              </List>  
              <TextArea
                rows={2}
                placeholder="Type your comment"
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                autoFocus
              />
              <div className="buttonWrapper" style={{ marginTop: '8px' }}>
                <Button type="link" onClick={() => setShowCommentPopover(false)}>
                  Close
                </Button>
                <Button type="primary" onClick={handleCommentSubmit}>
                  <SendOutlined />
                </Button>
              </div>
            </div>
        }
        />
      </>
  )
}

export default CommentPopover