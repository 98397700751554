import React, { useState } from "react";
import { ReactComponent as OnboardSVG } from '../../assets/onboard.svg';
import { Navigate,useNavigate } from "react-router-dom";
import axios from "axios";
import "./onboardUser.css";
import FullPageLayout from "../pageLayout/fullPageLayout";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Radio,
  Typography } from "antd";
import { userDataStore } from "../../store/user";
import API from "../../constants/api";
const { Title } = Typography;

const OnboardUser = () => {
  const userData = userDataStore((state) => state.userData);
  const loginCurrentUser = userDataStore((state) => state.loginCurrentUser);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onClickOnboardForm = async (formData) => {
    if ((radValue === "enterprise" || radValue === "academy")) {
      const newOrganization = {
        organization_name : formData.organization_name,
        organization_type: radValue,
        organization_data: {
          size: formData.company_size
        },
        created_by_email: userData.data.email
      };
      await axios.post(API.CREATE_ORG, newOrganization, {
        headers: {
          "Content-Type": "application/json",
          Authorization: userData.access_token,
        },
      })
      .then((response) => {
        userData.data.organization_id= response.data.organization_id;
        userData.data.organization_name = formData.organization_name;
      })
      .catch((err) => {
        console.log("Error creating Organization:", err);
      });
    } else if(radValue === "community") {
      userData.data.organization_id= "community";
      userData.data.organization_name = "Trusthive Community";
    }

    userData.data.onboarded = false;
    userData.data.invited = true;

    await axios.put(`${API.USER}/${userData.data.user_id}`, userData.data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: userData.access_token,
      },
    })
    .then((response) => {
      const {user} = response.data;
      loginCurrentUser(userData);
      if(user.invited && !user.onboarded) {
        navigate("/thanks-beta-user");
      } else {
        navigate("/");
      }
    })
    .catch((err) => {
      console.log("There was a problem onboarding the user: ", err);
    });
  };
const [radValue,setRadValue] = useState(1);
const onChange = (e) => {
  setRadValue(e.target.value);
};
  return (
    <>
      {userData.data.onboarded ? 
      ( <Navigate replace to={"/"} />) :
      (
        <FullPageLayout>
          <Row>
            <Col offset={1}>
              <div className="onboarding-box">
                <Title style={{fontWeight:700,marginBottom: 30}} level={2}>Hi {userData.data.first_name}, tell us a bit about you!</Title>
                <div style={{fontSize: 22,fontWeight:400,color:"#00000099", marginBottom: 20}}>
                What are you planning to use trusthive for?
                </div>
                <div>
                </div>         
                <Form
                  layout= "vertical"
                  name="onboard-form"
                  form={form}
                  onFinish={onClickOnboardForm}
                >
                  <Form.Item>
                  <Radio.Group style={{marginBottom:30}} onChange={onChange} value={radValue}>
                    <Space style={{color:"black",fontWeight:500}} direction="vertical">
                    <Radio value= "enterprise" style={{marginBottom:5}}>
                        Work - for my Organization's security needs
                      </Radio>        
                      <Radio value= "academy" style={{marginBottom:5}}>
                        Education - Part of School/University/Learning Program
                      </Radio>
                      <Radio value= "community" style={{marginBottom:5}}>
                        Personal - to learn about threat modelling and Security
                      </Radio>        
                    </Space>
                  </Radio.Group>
                  </Form.Item>
                  {(radValue === "enterprise" || radValue === "academy") && (<>
                  <Form.Item  label={radValue === "enterprise" ? "What’s the name of your organization?":"What’s the name of your School/University/Learning Program?"}  name="organization_name">
                    <Input style={{marginTop:10}} placeholder={radValue === "enterprise" ? "Enter your organization title":"Enter your School/University/Learning Program"} size="large" name="organization_name"/>
                  </Form.Item>
                  {radValue === "enterprise" && (
                  <Form.Item name="company_size" label="How many people work in your organization?">
                    <Select style={{marginTop:10}} placeholder="How many people work at your company?" size="large" name="company_size">
                      <Select.Option value="startup">Less than 10</Select.Option>
                      <Select.Option value="mid-size">10 - 100 employees</Select.Option>
                      <Select.Option value="large">100+ employees</Select.Option>
                    </Select>
                  </Form.Item>
                  )}
                  </>)}
                  <Form.Item className="form-button-bar">
                    <Button style={{width:"100%"}} type="primary" size="large" htmlType="submit">Submit</Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
            <Col span={12} offset={2}>
              <div className="onboarding-image">
                <OnboardSVG style={{width:"500px"}} />
              </div>
            </Col>
          </Row>
        </FullPageLayout> )}
    </>
  );
};

export default OnboardUser;