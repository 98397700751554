import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Typography
} from 'antd';
import PageLayout from '../pageLayout/application';
import Spacer from '../common/spacer';

const { Title } = Typography;

const ThreatLibrary = () => (
  <PageLayout>
    <Title level={2}>Threats Library</Title>
    <Spacer size={25} />
    <Row gutter={16}>
      <Col span={6}>
        <Card title={<Link to="/threat-library/trusthive">Trusthive Threats Catalog</Link>} bordered={false}>
          <p>This is the out-of-the-box catalog of threats that will be applied to all your projects</p>
        </Card>
      </Col>
    </Row>
  </PageLayout>
);

export default ThreatLibrary;