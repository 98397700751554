const threatCanvasList = [
    {
        key: 0,
        nodes: [
            {
                key: 'Client',
                id: '1',
                type: 'customNode',
                data: { type: 'Client' },
                position: { x: 250, y: 5 },
                className: 'light',
            },
            {
                key: 'group',
                id: '2',
                data: { label: 'Trust boundary' },
                position: { x: 100, y: 100 },
                className: 'light',
                style: { backgroundColor: 'rgba(255, 0, 0, 0.2)', width: 200, height: 200 },
            },
            {
                id: '2a',
                data: { label: 'Microservice 1' },
                position: { x: 10, y: 50 },
                parentNode: '2',
            },
            { id: '3', data: { label: 'Microservice 4' }, position: { x: 320, y: 100 }, className: 'light' },
            {
                id: '4',
                data: { label: 'Payment Platform' },
                position: { x: 320, y: 200 },
                className: 'light',
                style: { backgroundColor: 'rgba(255, 0, 0, 0.2)', width: 300, height: 300 },
            },
            {
                id: '4a',
                data: { label: 'Microservice 2' },
                position: { x: 15, y: 65 },
                className: 'light',
                parentNode: '4',
                extent: 'parent',
            },
            {
                id: '4b',
                data: { label: 'System boundary' },
                position: { x: 15, y: 120 },
                className: 'light',
                style: { backgroundColor: 'rgba(255, 0, 255, 0.2)', height: 150, width: 270 },
                parentNode: '4',
            },
            {
                id: '4b1',
                data: { label: 'Microservice 3' },
                position: { x: 20, y: 40 },
                className: 'light',
                parentNode: '4b',
            },
            {
                id: '4b2',
                data: { label: 'Platform service' },
                position: { x: 100, y: 100 },
                className: 'light',
                parentNode: '4b',
            },
        ],
        edges: [
            { id: 'e1-2', source: '1', target: '2', animated: true },
            { id: 'e1-3', source: '1', target: '3' },
            { id: 'e2a-4a', source: '2a', target: '4a' },
            { id: 'e3-4', source: '3', target: '4' },
            { id: 'e3-4b', source: '3', target: '4b' },
            { id: 'e4a-4b1', source: '4a', target: '4b1' },
            { id: 'e4a-4b2', source: '4a', target: '4b2' },
            { id: 'e4b1-4b2', source: '4b1', target: '4b2' },
        ]
    },
    {
        key: 1,
        nodes: [
            {
                key: 'DB',
                id: '1',
                type: 'customNode',
                data: { type: 'DB' },
                position: { x: 250, y: 5 },
                className: 'light',
            },
            {
                key: 'group',
                id: '2',
                data: { label: 'Trust boundary' },
                position: { x: 100, y: 100 },
                className: 'light',
                style: { backgroundColor: 'rgba(255, 0, 0, 0.2)', width: 200, height: 200 },
            },
            {
                id: '2a',
                data: { label: 'Microservice 1' },
                position: { x: 10, y: 50 },
                parentNode: '2',
            },
            { id: '3', data: { label: 'Microservice 4' }, position: { x: 320, y: 100 }, className: 'light' },
            {
                id: '4',
                data: { label: 'Payment Platform' },
                position: { x: 320, y: 200 },
                className: 'light',
                style: { backgroundColor: 'rgba(255, 0, 0, 0.2)', width: 300, height: 300 },
            },
            {
                id: '4a',
                data: { label: 'Microservice 2' },
                position: { x: 15, y: 65 },
                className: 'light',
                parentNode: '4',
                extent: 'parent',
            },
            {
                id: '4b',
                data: { label: 'System boundary' },
                position: { x: 15, y: 120 },
                className: 'light',
                style: { backgroundColor: 'rgba(255, 0, 255, 0.2)', height: 150, width: 270 },
                parentNode: '4',
            },
            {
                id: '4b1',
                data: { label: 'Microservice 3' },
                position: { x: 20, y: 40 },
                className: 'light',
                parentNode: '4b',
            },
            {
                id: '4b2',
                data: { label: 'Platform service' },
                position: { x: 100, y: 100 },
                className: 'light',
                parentNode: '4b',
            },
        ],
        edges: [
            { id: 'e1-2', source: '1', target: '2', animated: true },
            { id: 'e1-3', source: '1', target: '3' },
            { id: 'e2a-4a', source: '2a', target: '4a' },
            { id: 'e3-4', source: '3', target: '4' },
            { id: 'e3-4b', source: '3', target: '4b' },
            { id: 'e4a-4b1', source: '4a', target: '4b1' },
            { id: 'e4a-4b2', source: '4a', target: '4b2' },
            { id: 'e4b1-4b2', source: '4b1', target: '4b2' },
        ]
    }
];

export default threatCanvasList;