import React, { useState, useRef, useCallback } from 'react';
import {useParams} from 'react-router-dom';
import PageLayout from '../pageLayout/projectDetailsLayout';
import Canvas from '../canvas/canvas';
import projectsList from '../../data/projectsList';
import threatCanvasList from '../../data/threatCanvasList';

const ProjectDetails = () => {
  const {projectId} = useParams();
  let projectDetails = projectsList.find(o => o.key.toString() === projectId);
  let threatCanvas = threatCanvasList.find(o => o.key.toString() === projectId);

  return (
    <PageLayout projectDetails={projectDetails} projectStatus="review">
      <Canvas threatCanvasDetails={threatCanvas} projectStatus="review" />
    </PageLayout>
  );
};

export default ProjectDetails;