const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://trusthive-core.netlify.app/.netlify/functions/api';
// const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://trusthive-api.netlify.app/.netlify/functions/api';


const clickup = {
    client: '7JFY0JMLESL3WSQRIJSDRULE1B79EEP8',
    secret: '8MSN9FKT9TVO5X00M3R2WK6B7T9T6KA5MARIOBOANC8RMYGOKTEIF41P5MPJ91LX'
}

const API = {
    USER: `${BASE_URL}/user`,
    CHECK_USER: `${BASE_URL}/checkUser`,
    USERS: `${BASE_URL}/users`,
    USERS_BY_ORG: `${BASE_URL}/orgUsers`,
    CREATE_ORG: `${BASE_URL}/createOrganization`,
    UPDATE_ORG: `${BASE_URL}/updateOrganization`,
    ORGANIZATIONS: `${BASE_URL}/organizations`,
    CREATE_PROJECT: `${BASE_URL}/createProject`,
    UPDATE_PROJECT: `${BASE_URL}/updateProject`,
    DELETE_PROJECT: `${BASE_URL}/deleteProject`,
    PROJECTS: `${BASE_URL}/projects`,
    PROJECT_DETAILS: `${BASE_URL}/projectDetails`,
    UPDATE_PROJECT: `${BASE_URL}/updateProject`,
    THREAT_CATALOG: `${BASE_URL}/threatLibrary`,
    CREATE_THREAT_IN_LIBRARY: `${BASE_URL}/threatLibrary/createThreat`,
    SEARCH_USER: `${BASE_URL}/searchUser`,
    INVITE_USERS: `${BASE_URL}/invite`,
    CREATE_THREAT_TAG: `${BASE_URL}/createThreatTags`,
    THREAT_TAGS: `${BASE_URL}/threatTags`,
    THREAT_INTEL: `${BASE_URL}/threatIntelligence`,
    SECURITY_CHECKLIST:`${BASE_URL}/checklistItem`,
    CHECKLIST:`${BASE_URL}/checklist`,
    CREATE_COMPONENT_METADATA_TAG:`${BASE_URL}/createComponentMetadataTag`,
    COMPONENT_METADATA_TAGS:`${BASE_URL}/componentMetadataTags`,
    METADATA_LIBRARY:`${BASE_URL}/metadataLibrary`,
    WORKSPACE_DETAILS: `${BASE_URL}/workspace`,
};

export default API;