import React from 'react';
import PageLayout from '../pageLayout/application';

const Insights = () => (
  <PageLayout>
    <React.Fragment>
      This is Insights
    </React.Fragment>
  </PageLayout>
);

export default Insights;