import React, { useState } from "react";
import { ReactComponent as TBUSVG } from '../../assets/tbu.svg';
import { Link } from "react-router-dom";
import axios from "axios";
// import "./onboardUser.css";
import FullPageLayout from "../pageLayout/fullPageLayout";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Radio,
  Typography } from "antd";
const { Title } = Typography;

const ThanksBetaUser = () => {
  
  return (
    <>
        <FullPageLayout>
          <div style={{margin: "20px", fontSize: "24px", textAlign: "center"}}>
            <TBUSVG style={{width:"400px"}} />
            <p style={{ marginTop: "-70px" }}>
              Thank you for filling in all your details. We will get you onboarded very soon. <br />
              Lookout for an email from us in the next few days
            </p>
          </div>
        </FullPageLayout>
    </>
  );
};

export default ThanksBetaUser;