import createStore from 'zustand';
import persist from '../utils/persist';
import { googleLogout } from '@react-oauth/google';

export const userDataStore = createStore(
  persist(
    {
      key: 'userStore', //TODO Pankaj remove this
      denylist: ['isLoading', 'errorMessage'],
    },
    (set) => ({
        isLoading: false,
        errorMessage: '',
        userData: {},
        loginCurrentUser: (userDetails) => {
          set(state => ({
            userData: userDetails
        }))},
        logoutCurrentUser: id => {
          set(state => ({
            userData: {}
          }))
          googleLogout();
        }
    })
  )
);