import find from "lodash/find";

const THREAT_ACTORS = [
    {
        "id": 1,
        "title": "External Attacker",
        "related_recommendations": [
            {
                "id": 59,
                "title": "Use deserialization methods that don’t execute code"
            },
            {
                "id": 28,
                "title": "Validate user input prior to processing the request"
            },
            {
                "id": 97,
                "title": "Parsing CSV Securely"
            },
            {
                "id": 60,
                "title": "Configure XML parsers to reject DTDs"
            },
            {
                "id": 51,
                "title": "Constant Time Comparison"
            },
            {
                "id": 18,
                "title": "Use approved mechanisms to access your production environments"
            },
            {
                "id": 2,
                "title": "Use encryption in transit (HTTPS) to communicate between services"
            },
            {
                "id": 29,
                "title": "Implement secure HTTP Header information for Websites and Web API's"
            },
            {
                "id": 36,
                "title": "Plan to provide CSIRT access for incident response"
            },
            {
                "id": 1,
                "title": "Select the right authentication & authorization mechanism to identify and control access to users or services"
            },
            {
                "id": 57,
                "title": "Asymmetric Encryption - Pending Annual Review"
            },
            {
                "id": 4,
                "title": "Restrict the network connectivity to the required users or services (Network Segmentation)"
            },
            {
                "id": 40,
                "title": "Plan for data loss and disaster recovery"
            },
            {
                "id": 34,
                "title": "Ensure inbound/outbound traffic flows through a Security Gateway in 1st Party Datacenters"
            },
            {
                "id": 13,
                "title": "Ensure you are logging security-related events and information"
            },
            {
                "id": 10,
                "title": "Scan your containers for vulnerabilities at build time - Waiting for Annual Review"
            },
            {
                "id": 98,
                "title": "SMS-based 2FA Token Theft - Waiting Annual Review"
            },
            {
                "id": 48,
                "title": "Setting up TLS"
            },
            {
                "id": 27,
                "title": "Use adequate encoding mechanism to prevent security flaws"
            },
            {
                "id": 3,
                "title": "Use Encryption at Rest to protect stored information"
            },
            {
                "id": 54,
                "title": "User Password Storage: pbkdf2"
            },
            {
                "id": 53,
                "title": "User Password Storage: scrypt"
            },
            {
                "id": 52,
                "title": "User Password Storage"
            },
            {
                "id": 38,
                "title": "Ensure your email infrastructure implements industry-standard antispoofing and encryption mechanisms"
            },
            {
                "id": 137,
                "title": "Prevent CSRF Attacks"
            },
            {
                "id": 50,
                "title": "Generating Secrets"
            },
            {
                "id": 47,
                "title": "Cryptography"
            },
            {
                "id": 31,
                "title": "Prevent confuse deputy problem in software development"
            },
            {
                "id": 5,
                "title": "Filter and log outbound network calls"
            },
            {
                "id": 12,
                "title": "Ensure your artifacts are signed as part of your build process"
            },
            {
                "id": 11,
                "title": "Scan your code for known security vulnerabilities"
            },
            {
                "id": 96,
                "title": "Prevent malicious content in uploaded files"
            },
            {
                "id": 37,
                "title": "Separate CSS and Javascript into external files to allow for Content Security Policies"
            },
            {
                "id": 46,
                "title": "Filter the content of your visible error messages"
            },
            {
                "id": 45,
                "title": "Ensure your application enforces data upload limts"
            },
            {
                "id": 39,
                "title": "Ensure your DNS domains are properly monitored and maintained"
            },
            {
                "id": 7,
                "title": "Use a secure source code management solution to store and manage code"
            },
            {
                "id": 55,
                "title": "Hash vs. HMAC"
            },
            {
                "id": 56,
                "title": "Symmetric Encryption"
            },
            {
                "id": 58,
                "title": "Signing Messages"
            }
        ]
    },
    {
        "id": 2,
        "title": "Man in the Middle - Internal",
        "related_recommendations": [
            {
                "id": 95,
                "title": "Implement the right encryption mechanism to prevent Replay Attacks"
            },
            {
                "id": 49,
                "title": "Setting up GRPC"
            }
        ]
    },
    {
        "id": 3,
        "title": "Man in the Middle - External",
        "related_recommendations": [
            {
                "id": 101,
                "title": "Preventing JSON Injection"
            },
            {
                "id": 100,
                "title": "Preventing LDAP Injection"
            },
            {
                "id": 99,
                "title": "Making XPATh/XQueries Securely"
            }
        ]
    },
    {
        "id": 4,
        "title": "Malicious Process (Low Privileged Process)",
        "related_recommendations": []
    },
    {
        "id": 5,
        "title": "Malicious Service (Low Privileged Microservice)",
        "related_recommendations": []
    },
    {
        "id": 6,
        "title": "Internal Attacker (Admin)",
        "related_recommendations": []
    },
    {
        "id": 7,
        "title": "Internal Attacker (User)",
        "related_recommendations": [
            {
                "id": 20,
                "title": "Use a secure mechanism to manage your certificates for encryption in transit or signing"
            },
            {
                "id": 32,
                "title": "Use approved Key Management Systems (KMS) to generate and store encryption keys"
            },
            {
                "id": 8,
                "title": "Use a secure CI/CD pipeline for your project"
            },
            {
                "id": 35,
                "title": "Use an approved framework for software development"
            },
            {
                "id": 15,
                "title": "Use preapproved base images to build your services"
            },
            {
                "id": 19,
                "title": "Ensure your logs reach the Security Detection org"
            },
            {
                "id": 9,
                "title": "Ensure you are not checking secrets into source control or config files"
            },
            {
                "id": 33,
                "title": "Ensure your software is up-to-date"
            },
            {
                "id": 43,
                "title": "Ensure you keep secrets out of your logs"
            },
            {
                "id": 42,
                "title": "Protect against Configuration File Tampering"
            },
            {
                "id": 44,
                "title": "Ensure you use separate secrets in different environments"
            },
            {
                "id": 14,
                "title": "Harden your container orchestrators - Waiting Annual Review"
            }
        ]
    },
    {
        "id": 8,
        "title": "Malicious Package",
        "related_recommendations": [
            {
                "id": 41,
                "title": "Protect against Altered Binaries in Production - Pending Annual Review"
            },
            {
                "id": 25,
                "title": "Ensure Endpoints are properly monitored by security agents"
            },
            {
                "id": 16,
                "title": "Store Artifacts in approved repositories"
            },
            {
                "id": 23,
                "title": "Check your environment for vulnerabilties and bad configs"
            }
        ]
    },
    {
        "id": 9,
        "title": "Man in the Middle - Browser",
        "related_recommendations": []
    }
]

const getThreatActor = (t_id) => {
    let obj = find(THREAT_ACTORS, { id: t_id });
    return obj;
}


export {
    THREAT_ACTORS,
    getThreatActor
};