import find from "lodash/find";

const THREAT_RISK = [
    {
        id: "High",
        color: "red",
        label: "H"
    },
    {
        id: "Medium",
        color: "orange",
        label: "M"
    },
    {
        id: "Low",
        color: "yellow",
        label: "L"
    }
]

const getThreatRisk = (t_id) => {
    let obj = find(THREAT_RISK, { id: t_id });
    return obj;
}


export {
    THREAT_RISK,
    getThreatRisk
};