import {
    EllipsisOutlined
  } from '@ant-design/icons'
const projectsList = [
    {
        key: 0,
        title: 'Ecomerce AWS service',
        status: 'In-Progress',
        team: 'Avengers',
        createdDate: 'Dec 1 2022',
        lastModifiedDate: 'Just now',
        author: 'Tony Stark',
        noOfThreats:3,
        
    },
    {
        key: 1,
        title: 'Infra service uplift',
        status: 'Completed',
        team: 'Eternals',
        createdDate: 'Dec 1 2022',
        lastModifiedDate: '3 hours ago',
        author: 'Tony Stark',
        noOfThreats:30,
        
    },
    {
        key: 2,
        title: 'Notification Service',
        status: 'Review',
        team: 'Fantastic Four',
        createdDate: 'Dec 1 2022',
        lastModifiedDate: '10 minutes ago',
        author: 'Tony Stark',
        noOfThreats:9,
        
    },
    {
        key: 3,
        title: 'On prem service design',
        status: 'Completed',
        team: 'Guardians of the Galaxy',
        createdDate: 'Dec 1 2022',
        lastModifiedDate: '2 hours ago',
        author: 'Tony Stark',
        noOfThreats:7,
        icon: <EllipsisOutlined />,
    },
    {
        key: 4,
        title: 'Streaming service with Kafka',
        status: 'In-Progress',
        team: 'Avengers',
        createdDate: 'Dec 1 2022',
        lastModifiedDate: 'Yesterday',
        author: 'Tony Stark',
        noOfThreats:21,
       
    }
];

export default projectsList;