import React, { useState } from 'react';
import { Link } from "react-router-dom";
import PageLayout from '../pageLayout/application';
import {
  Button,
  Card,
  Col,
  Dropdown,
  message,
  Row,
  List,
  Space,
  Statistic,
  Table,
  Tag,
  Typography
} from 'antd';
import {
  DownOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  Cell,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  PieChart,
  Pie,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import home_area_chart from '../../data/home_area_chart';
import home_bar_chart from '../../data/home_bar_chart';
import home_pie_chart from '../../data/home_pie_chart';
import home_split_bar_chart from '../../data/home_split_bar_chart';
import threatsList from '../../data/threatsList';
import {userDataStore} from '../../store/user';
import useDocumentTitle from '../../utils/pageTitle';


const { Title } = Typography;
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Home = () => {
  useDocumentTitle("Home");
  const [collapsed, setCollapsed] = useState(false);
  const userData = userDataStore(state => state.userData);
  return (
  <PageLayout>
    <Row>
      <Col span={18}>
        {userData.data ? (
            <Title level={2}> Welcome {userData.data.name} </Title>    
          ) : (
            <Title level={2}> Home </Title>    
          )
        }
      </Col>
      <Col span={6}>
        <div style={{ padding: '25px 0 0 0', marginRight:15, textAlign: 'right' }}>
            <Button style={{ marginRight:15}}>
                My teams
                <DownOutlined />
            </Button>
            <Button>
                Last week
                <DownOutlined />
            </Button>
          </div>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={8}>
        <Card bordered={false}>
          <Statistic
            title="Pending Design reviews"
            value={7}
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card bordered={false}>
          <Statistic
            title="Threats detected"
            value={64}
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card bordered={false}>
          <Statistic
            title="Outdated designs"
            value={10}
            valueStyle={{ color: '#cf1322' }}
          />
        </Card>
      </Col>
    </Row>
    <div style={{marginBottom: 25}} />
    <Row gutter={16}>
      <Col span={12}>
        <Card title="Threats trend" bordered={false}>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart
              data={home_area_chart}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
            </AreaChart>
          </ResponsiveContainer>
        </Card>
      </Col>
      <Col span={12}>
        <Card title="STRRIDE" bordered={false}>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={home_bar_chart}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" stackId="a" fill="#8884d8" />
              <Bar dataKey="amt" stackId="a" fill="#82ca9d" />
              <Bar dataKey="uv" fill="#ffc658" />
            </BarChart>
            </ResponsiveContainer>
        </Card>
      </Col>
    </Row>
    <div style={{marginBottom: 25}} />
    <Row gutter={16}>
      <Col span={8}>
        <Card title="Threats by severity" bordered={false}>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={home_pie_chart}
                innerRadius={120}
                outerRadius={150}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {home_pie_chart.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Top Threats" bordered={false} height={300}>
          <List
            dataSource={threatsList}
            renderItem={(item) => (
              <List.Item>{item.title}</List.Item>
            )}
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Team/ Product (open threats, checklist adhereence" bordered={false}>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={home_split_bar_chart}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" fill="#8884d8" />
              <Bar dataKey="uv" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </Card>
      </Col>
    </Row>
  </PageLayout>
)};

export default Home;