import React, { useState } from "react";
import { ReactComponent as PNFSVG } from '../../assets/404.svg';
import { Link } from "react-router-dom";
import axios from "axios";
// import "./onboardUser.css";
import FullPageLayout from "../pageLayout/fullPageLayout";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Radio,
  Typography } from "antd";
const { Title } = Typography;

const PageNotFound = () => {
  
  return (
    <>
        <FullPageLayout>
          <div style={{margin: "20px", fontSize: "24px", textAlign: "center"}}>
            <PNFSVG style={{width:"400px"}} />
            <p style={{ marginTop: "-70px" }}>Looks like you got something you werent looking for!</p>
            <Button className="btn-outlined"><Link to="/projects">Go back to projects</Link></Button>
          </div>
        </FullPageLayout>
    </>
  );
};

export default PageNotFound;