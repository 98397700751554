import React, { useState } from "react";
import { Button, Drawer } from "antd";
import { sample2 } from "../../data/terraform";
import Spacer from "../common/spacer";
import { MarkerType } from "reactflow";

const parseTerraformScript = (script) => {
  const resourcePattern = /resource\s+"(\w+)"\s+"(\w+)"\s+\{([^}]+)\}/g;
  const variablePattern = /(\w+)\.([\w_]+)/g; // To match variable dependencies
  const resources = [];

  let match;
  while ((match = resourcePattern.exec(script)) !== null) {
    const [, type, name, body] = match;
    const dependencies = new Set();
    let varMatch;
    while ((varMatch = variablePattern.exec(body)) !== null) {
      dependencies.add(`${varMatch[1]}_${varMatch[2]}`);
    }
    resources.push({ type, name, dependencies: Array.from(dependencies) });
  }

  return resources;
};

const transformToReactFlow = (resources) => {
  const nodes = [];
  const edges = [];

  resources.forEach((resource) => {
    const id = `${resource.type}_${resource.name}`;
    nodes.push({
      id,
      type: "node",
      className: `group-node-${resource.type.toUpperCase()}`,
      data: {
        title: `${resource.type} ${resource.name}`,
        description: `Resource type: ${resource.type}`,
        icon: null,
        metadata: [],
        key: resource.name,
      },
      position: { x: Math.random() * 400, y: Math.random() * 400 },
      height: 60,
      width: 65,
    });

    resource.dependencies.forEach((dep) => {
      edges.push({
        id: `e-${dep}-${id}`,
        source: dep,
        target: id,
        type:'customEdge',
        data: { label: "Dataflow" },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
          color: "black",
        },
      });
    });
  });

  return { nodes, edges };
};

const scriptToFlowTransform = (saveProjectDetailsObjects) => {
  const resources = parseTerraformScript(sample2);
  const flow = transformToReactFlow(resources);
  saveProjectDetailsObjects("threat_canvas", flow);
};

const TerraformDrawer = ({
  onClose,
  open,
  projectDetails,
  saveProjectDetailsObjects,
}) => {
  const [tScript, settScript] = useState(sample2);
  return (
    <Drawer
      title="Enter terraform Script"
      placement="right"
      onClose={onClose}
      open={open}
      size={"large"}
      mask={false}
    >
      <code>{tScript};</code>
      <Spacer size={50} />
      <Button onClick={() => scriptToFlowTransform(saveProjectDetailsObjects)}>
        Generate threat Model
      </Button>
    </Drawer>
  );
};
export default TerraformDrawer;